import { drawerWidth, primaryColors, whiteColor, link } from '../../base';

export default theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1
    }
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  listItem: {
    margin: '10px 0',
    '&:first-child': {
      marginTop: 0
    },
    '&:hover': {
      background: primaryColors[0],
      color: whiteColor
    },
    '&:hover > div': {
      color: whiteColor
    }
  },
  listItemActive: {
    background: primaryColors[0],
    color: whiteColor
  },
  listItemIconActive: {
    color: whiteColor
  },
  ...link
});
