/**
 *  Helper function hexToRgb
 *
 *  examples:
 *    input: #9c27b0 => output = 156, 39, 176
 *    input: 9c27b0 => output = 156, 39, 176
 *    input: #999 => output = 153, 153, 153
 *    input: 999 => output = 153, 153, 153
 */

export const hexToRgb = input => {
  input = input + '';
  input = input.replace('#', '');
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error('input is not a valid hex color.');
  }

  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let third = input[2];
    input = first + first + second + second + third + third;
  }

  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let third = input[4] + input[5];

  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(
    third,
    16
  )}`;
};

/**
 * Variables - Styles that are used on more than one component
 */

export const transition = {
  transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
};

export const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 300,
  lineHeight: '1.5rem'
};

export const drawerWidth = 260;

export const primaryColors = ['#3f596a'];
export const blackColor = '#000';
export const whiteColor = '#fff';

export const boxShadow = {
  boxShadow:
    '0 10px 30px -12px rgba(' +
    hexToRgb(blackColor) +
    ', 0.42), 0 4px 25px 0px rgba(' +
    hexToRgb(blackColor) +
    ', 0.12), 0 8px 10px -5px rgba(' +
    hexToRgb(blackColor) +
    ', 0.2)'
};

export const link = {
  link: {
    color: whiteColor,
    '&:hover': {
      textDecoration: 'none'
    }
  }
};
