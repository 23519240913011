import { drawerWidth } from '../base';

export default (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: 80,
    width: `calc(100% - ${drawerWidth}px)`,
    position: 'relative',
    minHeight: '100vh',
    '& > div': {
      overflow: 'hidden',
    },
  },
});
