import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import { isEmpty } from '../../helpers';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

// Custom components
import InputField from '../inputs/InputField';
import DateField from '../inputs/DateField';
import SelectField from '../inputs/SelectField';
import FileField from '../inputs/FileField';
import CheckboxField from '../inputs/CheckboxField';
import SelectFieldMultiple from '../inputs/SelectFieldMultiple';
import AutoCompleteSingle from '../inputs/AutoComplete/AutoCompleteSingle';
import AutoCompleteMultiple from '../inputs/AutoComplete/AutoCompleteMultiple';

// Styles
import { transition } from '../../assets/jss/base';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  expand: {
    margin: '10px 0',
  },
  expandDetails: {
    flexFlow: 'column',
  },
  formGroup: {
    position: 'relative',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: theme.shape.borderRadius,
    paddingTop: 60,
    marginBottom: 10,
    justifyContent: 'center',
    ...transition,
    '&:hover': {
      borderColor: '#fff',
    },
  },
  deleteButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 99,
  },
}));

const UniversalForm = ({
  fields,
  formFieldGroups,
  handleSubmit,
  validationSchema,
}) => {
  console.log(fields, formFieldGroups);
  const { t } = useTranslation();
  const classes = useStyles();
  const [initialValues] = useState(
    fields.reduce((o, f) => {
      if (f.name.includes('.')) {
        const pieces = f.name.split('.');
        const root = pieces[0];
        const index = pieces[1];
        const code = pieces[2];
        if (root in o) {
          if (isEmpty(o[root][index])) {
            o[root].push({ [code]: f.value });

            return o;
          }

          o[root][index][code] = f.value;
          return o;
        }

        return {
          ...o,
          [root]: [
            {
              [code]: f.value,
            },
          ],
        };
      }

      return { ...o, [f.name]: f.value };
    }, {})
  );

  const [expanded, setExpanded] = useState(null);
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [innerFields, setInnerFields] = useState(fields);
  const addComplexItem = (code, index, formValues, setFormValues) => {
    let newFormValues = Object.assign({}, formValues);
    // Update fields
    let newFields = innerFields.filter((f) =>
      f.name.includes(`${code}.${index}`)
    );

    let initialValues = Object.assign({}, newFormValues[code][index]);
    newFields = newFields.map((f) => {
      let field = Object.assign({}, f);
      field.name = field.name.replace(`${index}`, `${index + 1}`);

      const pieces = field.name.split('.');
      if (['date', 'datetime'].includes(field.type)) {
        field.value = null;
        initialValues[pieces[pieces.length - 1]] = null;
      } else {
        field.value = '';
        initialValues[pieces[pieces.length - 1]] = '';
      }

      return field;
    });

    setInnerFields([...innerFields, ...newFields]);

    // Update form values
    newFormValues[code].push(initialValues);
    setFormValues(newFormValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          const result = await handleSubmit(values);

          result && actions.resetForm();
        } catch (err) {
          for (let key in err) {
            actions.setFieldError(key, err[key]);
          }
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        setValues,
      }) => {
        const simpleFields = [];
        const groupFields = [];

        innerFields.forEach((field) => {
          if (!field.hidden) {
            if (!isEmpty(field.dependOn) && isEmpty(values[field.dependOn]))
              return null;

            let isComplex = field.name.includes('.');
            let container = simpleFields;
            let value = values[field.name];
            let error = errors[field.name];
            let root = '';
            let index = 0;

            if (isComplex) {
              container = groupFields;

              const pieces = field.name.split('.');
              root = pieces[0];
              index = pieces[1];
              const code = pieces[2];

              value = values[root][index][code];
              error =
                errors[root] &&
                errors[root][index] &&
                errors[root][index][code];
            }

            switch (field.type) {
              case 'text':
              case 'number':
              case 'email':
              case 'password':
                container.push({
                  group: root,
                  index,
                  component: (
                    <InputField
                      key={field.name}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                      initialValue={value}
                      error={error && t(error)}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      dependency={
                        !isEmpty(field.dependOn)
                          ? { [field.dependOn]: values[field.dependOn] }
                          : null
                      }
                      mask={field.mask}
                    />
                  ),
                });
                break;
              case 'date':
              case 'datetime':
                container.push({
                  group: root,
                  index,
                  component: (
                    <DateField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      initialValue={value}
                      error={error && t(error)}
                      handleChange={handleChange}
                      dependency={
                        !isEmpty(field.dependOn)
                          ? { [field.dependOn]: values[field.dependOn] }
                          : null
                      }
                    />
                  ),
                });
                break;
              case 'combobox':
                if (field.isMultiple) {
                  container.push({
                    group: root,
                    index,
                    component: (
                      <SelectFieldMultiple
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        initialValue={value}
                        error={error && t(error)}
                        handleChange={handleChange}
                        participations={field.participations}
                        customHandle={
                          field.customHandle ? field.customHandle : null
                        }
                        dependency={
                          !isEmpty(field.dependOn)
                            ? { [field.dependOn]: values[field.dependOn] }
                            : null
                        }
                      />
                    ),
                  });
                  break;
                }

                container.push({
                  group: root,
                  index,
                  component: (
                    <SelectField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      initialValue={value}
                      error={error && t(error)}
                      handleChange={handleChange}
                      participations={field.participations}
                      customHandle={
                        field.customHandle ? field.customHandle : null
                      }
                      dependency={
                        !isEmpty(field.dependOn)
                          ? { [field.dependOn]: values[field.dependOn] }
                          : null
                      }
                    />
                  ),
                });
                break;
              case 'autocomplete':
                if (field.isMultiple) {
                  container.push({
                    group: root,
                    index,
                    component: (
                      <AutoCompleteMultiple
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        initialValue={value}
                        error={error && t(error)}
                        handleChange={setFieldValue}
                        participations={field.participations}
                        customHandle={
                          field.customHandle ? field.customHandle : null
                        }
                        dependency={
                          !isEmpty(field.dependOn)
                            ? { [field.dependOn]: values[field.dependOn] }
                            : null
                        }
                      />
                    ),
                  });
                  break;
                }

                container.push({
                  group: root,
                  index,
                  component: (
                    <AutoCompleteSingle
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      initialValue={value}
                      error={error && t(error)}
                      handleChange={handleChange}
                      participations={field.participations}
                      customHandle={
                        field.customHandle ? field.customHandle : null
                      }
                      dependency={
                        !isEmpty(field.dependOn)
                          ? { [field.dependOn]: values[field.dependOn] }
                          : null
                      }
                    />
                  ),
                });
                break;
              case 'file':
                container.push({
                  group: root,
                  index,
                  component: (
                    <FileField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      error={error && t(error)}
                      handleChange={(e) =>
                        setFieldValue(field.name, e.currentTarget.files[0])
                      }
                      accept={field.accept ? field.accept : ''}
                      dependency={
                        !isEmpty(field.dependOn)
                          ? { [field.dependOn]: values[field.dependOn] }
                          : null
                      }
                    />
                  ),
                });
                break;
              case 'checkbox':
                container.push({
                  group: root,
                  index,
                  component: (
                    <CheckboxField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      initialValue={value}
                      handleChange={(e) =>
                        setFieldValue(field.name, e.currentTarget.checked)
                      }
                    />
                  ),
                });
                break;
              default:
                return null;
            }
          } else return null;
        });

        const groupsFieldsGrouped = groupFields
          .sort((f) => f.group)
          .reduce((acc, f) => {
            if (f.group in acc) {
              if (isEmpty(acc[f.group][f.index]))
                acc[f.group][f.index] = [f.component];
              else acc[f.group][f.index].push(f.component);

              return acc;
            }

            return { ...acc, [f.group]: [[f.component]] };
          }, {});

        let complexContent = [];
        for (const groupKey in groupsFieldsGrouped) {
          const group = formFieldGroups.find((g) => g.code === groupKey);
          let groupName = group ? group.name['nameRu'] : groupKey;

          const gContent = (
            <ExpansionPanel
              key={groupKey}
              className={classes.expand}
              expanded={expanded === groupKey}
              onChange={handleExpand(groupKey)}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${groupKey}-content"`}
                id={`${groupKey}-header"`}
              >
                <Typography>{groupName}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expandDetails}>
                {groupsFieldsGrouped[groupKey].map((sub, idx) => (
                  <FormGroup key={idx} className={classes.formGroup}>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => {
                        // clear innerfields
                        if (values[groupKey].length !== 1) {
                          const newFields = innerFields.filter(
                            (f) => !f.name.includes(`${groupKey}.${idx}`)
                          );
                          setInnerFields(newFields);

                          const newValue = values[groupKey];
                          newValue.splice(idx, 1);

                          setValues({
                            ...values,
                            [groupKey]: newValue,
                          });
                        } else {
                          const newValue = values[groupKey][0];
                          for (const key in newValue) {
                            newValue[key] =
                              newValue[key] instanceof Date ? null : '';
                          }

                          setValues({
                            ...values,
                            [groupKey]: [newValue],
                          });
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    {sub}
                  </FormGroup>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    addComplexItem(
                      groupKey,
                      groupsFieldsGrouped[groupKey].length - 1,
                      values,
                      setValues
                    )
                  }
                >
                  <AddIcon />
                </Button>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );

          complexContent.push(gContent);
        }
        return (
          <Form style={{ textAlign: 'center', marginBottom: 30 }}>
            {simpleFields.map((f) => f.component)}
            {complexContent}
            <div style={{ marginTop: 15 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {t('COMMON.SAVE')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UniversalForm;
