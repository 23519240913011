import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxField = ({ name, label = '', initialValue, handleChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={initialValue} onChange={handleChange} name={name} />
      }
      label={label}
    />
  );
};

export default CheckboxField;
