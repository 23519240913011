import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import svg from '../../assets/images/spinner.svg';

// Styles
import spinnerStyles from '../../assets/jss/components/common/spinnerStyles';
const useStyle = makeStyles(spinnerStyles);

const Spinner = () => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={svg} alt="Loading spinner..." />
    </div>
  );
};

export default Spinner;
