import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { createNotifier, closeNotifier } from '../../actions/snackbarActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';

// Custom hooks
import { useHttpGet } from '../../hooks/http';
import { isEmpty } from '../../helpers';

// Styles
import selectFieldStyles from '../../assets/jss/components/inputs/inputStyles';
const useStyle = makeStyles(selectFieldStyles);

const SelectField = ({
  name,
  label = '',
  initialValue = '',
  handleChange,
  customHandle = null,
  dependency = null,
  participations = null,
  error = null,
  allowedValues = null,
  createNotifier,
  closeNotifier,
  lang,
}) => {
  const classes = useStyle();
  const inputRef = useRef(null);
  const isMounted = useRef(false);

  const [localDependency, setLocalDependency] = useState(dependency);
  useEffect(() => {
    isMounted.current = true;
    if (!isEqual(dependency, localDependency) && isMounted.current) {
      setLocalDependency(dependency);
      handleChange({ target: { name: name, value: '' } });
    }

    return () => {
      isMounted.current = false;
    };
  }, [dependency, localDependency, handleChange, name]);

  let params = localDependency !== null ? { ...localDependency } : {};
  if (!isEmpty(participations)) {
    params = { ...params, participations };
  }

  const [, fetchedData] = useHttpGet(
    `/api/directories/${customHandle != null ? customHandle : name}`,
    [localDependency],
    params,
    {
      createNotifier,
      closeNotifier,
    }
  );

  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    isMounted.current = true;

    if (isMounted.current) setLabelWidth(inputRef.current.offsetWidth);

    return () => {
      isMounted.current = false;
    };
  }, []);

  let values = fetchedData;
  if (allowedValues !== null && fetchedData)
    values = values.filter((x) => allowedValues.includes(x.id));

  return (
    <Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputRef} htmlFor={name}>
          {label}
        </InputLabel>
        <Select
          value={initialValue}
          onChange={handleChange}
          input={
            <OutlinedInput labelWidth={labelWidth} name={name} id={name} />
          }
        >
          <MenuItem value="">
            <em>---</em>
          </MenuItem>
          {values &&
            values.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name['name' + lang]}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {error && (
        <Typography
          component="div"
          variant="caption"
          color="error"
          style={{ textAlign: 'center' }}
        >
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  SelectField
);
