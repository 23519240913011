import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#6b8698',
      main: '#3f596a',
      dark: '#14303f',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ff5c68',
      main: '#ed143d',
      dark: '#b20017',
      contrastText: '#ffffff',
    },
    background: {
      default: '#222935',
      paper: '#2f3441',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Comfortaa',
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '#ffffff',
        '&$focused': {
          color: '#ffffff',
        },
      },
    },
  },
});
