import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  createNotifier,
  closeNotifier,
} from '../../../actions/snackbarActions';
import { httpPost } from '../../../helpers';
import PushForm from './form/PushForm';
import Paper from '@material-ui/core/Paper';
import SelectField from '../../inputs/SelectField';

const PushNotification = ({ createNotifier, closeNotifier, lang }) => {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const [topic, setTopic] = useState('');

  const handlePushFormSubmit = async (data) => {
    const formData = new FormData();
    formData.append('topic', topic);
    for (var key in data) {
      formData.append(key, data[key]);
    }

    setSending(true);
    try {
      const response = await httpPost(
        '/api/pushnotification/',
        formData,
        {
          createNotifier,
          closeNotifier,
          translator: t,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response) {
        return true;
      }
    } finally {
      setSending(false);
    }

    return false;
  };

  return (
    <div>
      <Paper style={{ marginBottom: 15, textAlign: 'center', padding: 15 }}>
        <SelectField
          name="topic"
          initialValue={topic}
          label={t('PUSH_NOTIFICATION.TOPIC')}
          customHandle="pushTopic"
          handleChange={(e) => setTopic(e.target.value)}
        />
      </Paper>
      {topic !== '' && (
        <PushForm sending={sending} handleFormSubmit={handlePushFormSubmit} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  PushNotification
);
