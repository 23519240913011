import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

// Custom components
import Spinner from '../../common/Spinner';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import { useHttpGet } from '../../../hooks/http';

// Styles
import passportStyles from '../../../assets/jss/components/filterableTable/table/passportStyles';
import { isEmpty } from '../../../helpers';
const useStyles = makeStyles(passportStyles);

const Passport = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { open, handle, viewId, selectedId, lang, handleClose } = props;

  const [
    isLoading,
    fetchedData,
  ] = useHttpGet(
    `/api/${handle}/passport/${selectedId}`,
    [handle, selectedId],
    { viewId }
  );

  return (
    !isEmpty(fetchedData) && (
      <Paper className={clsx(classes.root, { [classes.open]: open })}>
        <AppBar className={classes.appBar} position="relative">
          <ToolBar>
            <Typography variant="h4" className={classes.title}>
              {t('PASSPORT.TITLE')}
            </Typography>
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </ToolBar>
        </AppBar>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={classes.body}>
            {fetchedData &&
              fetchedData.hasOwnProperty('properties') &&
              fetchedData.properties.map((p, idx) => (
                <div key={idx} className={classes.property}>
                  <Typography variant="h6" className={classes.propertyTitle}>
                    {p.name['name' + lang]}
                  </Typography>
                  <Typography variant="body1" className={classes.propertyBody}>
                    {p.value['name' + lang] && p.value['name' + lang].length > 0
                      ? p.value['name' + lang]
                      : '-'}
                  </Typography>
                </div>
              ))}
          </div>
        )}
      </Paper>
    )
  );
};

export default Passport;
