import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

// Custom components
import { createFilters } from '../../../../helpers/tableFilter';

// Icons
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';

// Styles
import filterBlockStyles from '../../../../assets/jss/components/filterableTable/filter/filterBlockStyles';
const useStyle = makeStyles(filterBlockStyles);

const FilterBlock = (props) => {
  const classes = useStyle();
  const {
    lang,
    registersData,
    filterValues,
    handleChange,
    handleSearchClick,
  } = props;

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [showExtraFilters, setShowExtraFilters] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    handleSearchClick(false);
  };

  return (
    <Paper className={classes.root} style={{ margin: '15px 0' }}>
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        {createFilters(
          lang,
          registersData.filter((x) => x.isMain),
          filterValues,
          handleChange,
          setErrors,
          errors
        )}
        {showExtraFilters &&
          createFilters(
            lang,
            registersData.filter((x) => !x.isMain),
            filterValues,
            handleChange,
            setErrors,
            errors
          )}
        <div className={classes.controls}>
          <Typography style={{ margin: '0 15px' }}>
            {t('PUSH_NOTIFICATION.FILTER.EXTRA_FIELDS')}
            {'  '}
            <IconButton onClick={() => setShowExtraFilters(!showExtraFilters)}>
              <ArrowDropDownCircleIcon
                color="primary"
                className={clsx(classes.arrowIcon, {
                  [classes.arrowIconUp]: showExtraFilters,
                })}
              />
            </IconButton>
          </Typography>
          <div className={classes.formActions}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btn}
            >
              {t('BUTTON.APPLY')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={() => handleSearchClick(true)}
            >
              {t('BUTTON.CLEAR')}
            </Button>
          </div>
        </div>
      </form>
    </Paper>
  );
};

export default FilterBlock;
