import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import qs from 'qs';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Group from '@material-ui/icons/Group';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BackupIcon from '@material-ui/icons/Backup';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NotificationsIcon from '@material-ui/icons/Notifications';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';

// Styles
import sideBarStyles from '../../assets/jss/components/navbars/sidebarStyles';
const useStyle = makeStyles(sideBarStyles);

const SideBar = ({ history, open, auth, handleSideBarClose }) => {
  const classes = useStyle();

  const isActive = (path) => {
    const curPathArray = window.location.pathname.split('/');
    const { viewId = null, filterId = null } = qs.parse(
      window.location.search.substring(1)
    );

    const pathArray = path.split('/');
    const handle = pathArray[pathArray.length - 1].split('?')[0];
    const {
      viewId: pathViewId = null,
      filterId: pathFilterId = null,
    } = qs.parse(pathArray[pathArray.length - 1].split('?')[1]);

    return (
      curPathArray[curPathArray.length - 1] === handle &&
      viewId === pathViewId &&
      filterId === pathFilterId
    );
  };

  useEffect(() => {}, [history.location.pathname]);

  const { t } = useTranslation();

  const menuItems = [
    {
      text: t('SIDE_BAR.EMPLOYEES'),
      to: '/tables/Employees',
      icon: <AccountBalanceIcon />,
    },
    {
      text: t('SIDE_BAR.STUDENTS'),
      to: '/tables/Students',
      icon: <Group />,
    },
    {
      text: t('SIDE_BAR.PARENTS'),
      to: '/tables/Parents',
      icon: <SupervisedUserCircle />,
    },    
    {
      text: t('SIDE_BAR.SELECTION_COMMITTEE'),
      to: '/tables/Enrollment',
      icon: <CastForEducationIcon />,
    },
    {
      text: t('SIDE_BAR.AHD'),
      to: '/ahd',
      icon: <BackupIcon />,
    },
    {
      text: t('SIDE_BAR.REFERENCES'),
      to: '/references',
      icon: <LibraryBooksIcon />,
    },
    {
      text: t('SIDE_BAR.PUSH_NOTIFICATION'),
      to: '/push-notification',
      icon: <NotificationsIcon />,
    },
    {
      text: t('SIDE_BAR.BYPASS'),
      to: '/bypass',
      icon: <TransferWithinAStationIcon />,
    }
  ];

  if (auth.data.userGroup === 'admin')
    menuItems.push({
      text: t('SIDE_BAR.SCHEDULE'),
      to: '/schedule',
      icon: <AssignmentIcon />,
    });

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.tooltip}>
        <IconButton onClick={handleSideBarClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Link
            key={item.text}
            component={RouterLink}
            to={item.to}
            className={classes.link}
          >
            <Tooltip title={item.text} placement="right">
              <ListItem
                key={item.text}
                button
                className={clsx(classes.listItem, {
                  [classes.listItemActive]: isActive(item.to),
                })}
              >
                <ListItemIcon
                  className={clsx({
                    [classes.listItemIconActive]: isActive(item.to),
                  })}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
    </Drawer>
  );
};

export default withRouter(SideBar);
