import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = ({
  open,
  title,
  content,
  handleClose
}) => {
  return (
    <Dialog open={open} onClose={handleClose} scroll="body" aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" >{title}</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
    </Dialog>
  )
}

export default FormDialog;