import React, { useState, useEffect } from 'react';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const useHttpGet = (
  url,
  dependencies,
  params = {},
  notifActions = {}
) => {
  const [isLoading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    if (!dependencies.includes(undefined)) {
      setLoading(true);

      axios
        .get(url, {
          params,
        })
        .then((response) => {
          setFetchedData(response.data);
        })
        .catch((e) => {
          setFetchedData(false);

          const {
            createNotifier = function () {},
            closeNotifier = function () {},
          } = notifActions;

          createNotifier({
            message: 'Не удалось получить данные',
            options: {
              variant: 'warning',
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <IconButton onClick={() => closeNotifier(key)}>
                  <CloseIcon />
                </IconButton>
              ),
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, dependencies);

  return [isLoading, fetchedData];
};

export const useHttpPost = (url, body, dependencies, notifActions) => {
  const [isLoading, setLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    setLoading(true);

    axios
      .post(url, body)
      .then((response) => {
        setFetchedData(response.data);
      })
      .catch((e) => {
        setFetchedData(false);

        const {
          createNotifier = function () {},
          closeNotifier = function () {},
        } = notifActions;

        createNotifier({
          message: 'Не удалось получить данные',
          options: {
            variant: 'warning',
            key: new Date().getTime() + Math.random(),
            action: (key) => (
              <IconButton onClick={() => closeNotifier(key)}>
                <CloseIcon />
              </IconButton>
            ),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, dependencies);

  return [isLoading, fetchedData];
};
