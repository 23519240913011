import * as Yup from 'yup';

export default Yup.object().shape({
  excelFile: Yup.mixed()
    .test('fileFormat', 'Excel files only', value => {
      return (
        value &&
        [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ].includes(value.type)
      );
    })
    .required('File is required')
});
