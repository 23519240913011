import { CHANGE_LANG } from '../actions/types';

const initialState = {
  lang: 'Ru'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANG:
      return {
        lang: action.payload
      };
    default:
      return state;
  }
}
