import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  createNotifier,
  closeNotifier,
} from '../../../actions/snackbarActions';
import { httpGet, httpPost, isEmpty } from '../../../helpers';
import Spinner from '../../common/Spinner';
import PushRolesFilter from './roles-filter/PushRolesFilter';
import PushMainFilter from './main-filter/PushMainFilter';
import PushForm from './form/PushForm';

const PushNotification = ({ createNotifier, closeNotifier, lang }) => {
  const { t } = useTranslation();
  const isMounted = useRef(false);

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [rolesFilter, setRolesFilter] = useState({
    roleId: '',
    category: '',
  });
  const [mainFilterSettings, setMainFilterSettings] = useState({});
  const [mainFilter, setMainFilter] = useState({});
  const [mainFilterLoading, setMainFilterLoading] = useState(false);
  const [receivers, setReceivers] = useState({});
  const [sending, setSending] = useState(false);

  useEffect(() => {
    isMounted.current = true;

    const fetch = async () => {
      setLoading(true);
      try {
        const settingsResponse = await httpGet(
          '/api/pushnotification/admin/settings',
          {},
          {
            createNotifier,
            closeNotifier,
            translator: t,
          }
        );
        if (settingsResponse) {
          setSettings(settingsResponse);
        }
      } finally {
        setLoading(false);
      }
    };

    if (isMounted.current) fetch();

    return () => {
      isMounted.current = false;
    };
  }, [createNotifier, closeNotifier, t]);

  const handleRolesFilterChange = (e) => {
    setRolesFilter({ ...rolesFilter, [e.target.name]: e.target.value });
  };

  const handleRolesFormSubmit = async (e) => {
    e.preventDefault();

    const response = await httpGet(
      '/api/pushnotification/admin/filters',
      {
        roleId: rolesFilter.roleId,
      },
      {
        createNotifier,
        closeNotifier,
        translator: t,
      }
    );

    if (response) setMainFilterSettings(response);
  };

  const handleMainFilterChange = (e) => {
    setMainFilter({ ...mainFilter, [e.target.name]: e.target.value });
  };

  const handleMainFilterSubmit = async (clean) => {
    if (clean) {
      setMainFilter({});
      setReceivers({});
      return;
    }

    setMainFilterLoading(true);
    try {
      const response = await httpPost(
        '/api/pushnotification/admin/receivers?roleId=' + rolesFilter.roleId,
        {
          ...mainFilter,
          oldFilter:
            Object.keys(receivers).length > 0 ? receivers.filterId : null,
        },
        {
          createNotifier,
          closeNotifier,
          translator: t,
        }
      );
      if (response) {
        setReceivers(response);
      }
    } finally {
      setMainFilterLoading(false);
    }
  };

  const handlePushFormSubmit = async (data) => {
    const formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    formData.append('category', rolesFilter.category);
    formData.append('filterId', receivers.filterId);

    setSending(true);
    try {
      const response = await httpPost(
        '/api/pushnotification/',
        formData,
        {
          createNotifier,
          closeNotifier,
          translator: t,
        },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response) {
        return true;
      }
    } finally {
      setSending(false);
    }

    return false;
  };

  return (
    <div>
      {loading || isEmpty(settings) ? (
        <Spinner />
      ) : (
        <Fragment>
          <PushRolesFilter
            filterValues={rolesFilter}
            handleChange={handleRolesFilterChange}
            handleSubmit={handleRolesFormSubmit}
            settings={settings}
            clearFilter={() => {
              setRolesFilter({ roleId: '', category: '' });
              setMainFilterSettings({});
              setReceivers({});
            }}
          />
          {!isEmpty(mainFilterSettings) && (
            <PushMainFilter
              lang={lang}
              registersData={mainFilterSettings}
              filterValues={mainFilter}
              handleChange={handleMainFilterChange}
              handleSearchClick={handleMainFilterSubmit}
            />
          )}
          {mainFilterLoading ? (
            <Spinner />
          ) : (
            !isEmpty(receivers) && (
              <PushForm
                count={receivers.count}
                sending={sending}
                handleFormSubmit={handlePushFormSubmit}
              />
            )
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  PushNotification
);
