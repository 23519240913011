import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changeLang } from '../../actions/langActions';
import { capitalizeFirstLetter } from '../../helpers';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import LanguageIcon from '@material-ui/icons/Language';

const LangMenu = (props) => {
  const [, i18n] = useTranslation('common', { useSuspense: false });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
    props.changeLang(capitalizeFirstLetter(lang));
    handleClose();
  };

  return (
    <div className="LangMenu">
      <IconButton
        variant="contained"
        style={{ color: '#fff' }}
        aria-controls="lang-menu"
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleChange('kk')} value="kk">
          KK
        </MenuItem>
        <MenuItem onClick={() => handleChange('ru')} value="ru">
          RU
        </MenuItem>
        <MenuItem onClick={() => handleChange('en')} value="en">
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};

export default connect(null, { changeLang })(LangMenu);
