export default (theme) => ({
  root: {
    width: 400,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: '0 0 5px rgba(255,255,255,.3)',
    marginRight: -500,
    transition: 'margin .5s ease',
  },
  open: {
    marginRight: 0,
  },
  appBar: {
    height: 62,
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
  },
  body: {
    display: 'block',
    padding: '15px 20px',
    overflow: 'auto',
    height: 'calc(100% - 62px)',
  },
  property: {
    marginTop: 7,
    marginBottom: 7,
  },
  propertyTitle: {
    fontSize: 18,
  },
  propertyBody: {
    fontSize: 14,
    color: '#c7c7c7',
  },
});
