export default (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    transition: 'all .4s ease',
  },
  formActions: {
    maxWidth: 420,
  },
  btn: {
    margin: '0 8px',
  },
  controls: {
    paddingTop: theme.spacing(3),
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrowIcon: {
    transform: 'rotate(0)',
    transition: 'transform .8s ease',
  },
  arrowIconUp: {
    transform: 'rotate(-180deg)',
  },
});
