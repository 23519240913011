import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Styles
import titleStyles from '../../assets/jss/components/title/titleStyles';
const useStyle = makeStyles(titleStyles);

const Title = props => {
  const classes = useStyle();
  const { text } = props;

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.text}>
        {text}
      </Typography>
      <Divider />
    </div>
  );
};

export default Title;
