import { drawerWidth, whiteColor } from '../../base';

export default theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    height: 56,
    [theme.breakpoints.up('sm')]: {
      height: 64
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: 'none'
  },
  menuHamburgerButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  titleLink: {
    color: whiteColor,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      margin: '0 5px'
    }
  }
});
