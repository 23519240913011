import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import SelectField from '../../../inputs/SelectField';

import filterBlockStyles from '../../../../assets/jss/components/filterableTable/filter/filterBlockStyles';
const useStyles = makeStyles(filterBlockStyles);

const PushRolesFilter = ({
  filterValues,
  handleChange,
  handleSubmit,
  clearFilter,
  settings,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        <SelectField
          name="roleId"
          initialValue={filterValues['roleId']}
          label={t('PUSH_NOTIFICATION.FILTER.ROLE')}
          customHandle="roles"
          allowedValues={settings.allowedRoles}
          handleChange={handleChange}
        />
        <SelectField
          name="category"
          initialValue={filterValues['category']}
          label={t('PUSH_NOTIFICATION.FILTER.CATEGORY')}
          customHandle="pushNotificationCategory"
          allowedValues={settings.allowedCategories}
          handleChange={handleChange}
        />
        <div className={classes.formActions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.btn}
            disabled={
              filterValues['roleId'] === '' || filterValues['category'] === ''
            }
          >
            {t('BUTTON.APPLY')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={clearFilter}
          >
            {t('BUTTON.CLEAR')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default PushRolesFilter;
