import React from 'react';
import { useTranslation } from 'react-i18next';
import HorizontalTabs from '../../tabs/HorizontalTabs';
import ReferencesLogs from './ReferencesLogs';

const Logs = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      tab: {
        label: t('REFERENCES_LOGS.TABS.STUDENTS_REFERENCES'),
      },
      tabpanel: <ReferencesLogs type="references" />,
    },
    {
      tab: {
        label: t('REFERENCES_LOGS.TABS.EMPLOYEES_REFERENCES'),
      },
      tabpanel: <ReferencesLogs type="employeesReferences" />,
    },
    {
      tab: {
        label: t('REFERENCES_LOGS.TABS.APPLICATIONS'),
      },
      tabpanel: <ReferencesLogs type="applications" />,
    },
  ];

  return <HorizontalTabs data={tabs} />;
};

export default Logs;
