import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { removeNotifier } from '../../actions/snackbarActions';

class Notifier extends React.Component {
  displayed = [];

  storeDisplayed = (key) => {
    this.displayed.push(key);
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const {
      notifications: currentSnacks,
      closeSnackbar,
      removeNotifier,
    } = this.props;

    let notExists = false;
    for (let i = 0; i < newSnacks.length; i++) {
      const newSnack = newSnacks[i];

      if (newSnack.dismissed) {
        closeSnackbar(newSnack.key);
        removeNotifier(newSnack.key);
      }

      if (notExists) continue;

      notExists =
        notExists ||
        !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }

    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(({ key, message, options = {} }) => {
      if (this.displayed.includes(key)) return;

      this.props.enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }

          removeNotifier(key);
        },
      });

      this.storeDisplayed(key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  notifications: state.snackbars.notifications,
});

export default withSnackbar(
  connect(mapStateToProps, { removeNotifier })(Notifier)
);
