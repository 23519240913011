import React from 'react';
import TopNavBar from '../navbars/TopNavBar';

const Header = ({ sideBarOpen, handleSideBarOpen }) => {
  return (
    <TopNavBar drawerOpen={sideBarOpen} handleSideBarOpen={handleSideBarOpen} />
  );
};

export default Header;
