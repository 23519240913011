import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Link from '@material-ui/core/Link';

import {
  isEmpty,
  httpGet,
  httpPut,
  httpPost,
  generateYupSchemaShape,
  httpDelete,
} from '../../../helpers';
import { canEdit, canCreate, canDelete } from '../../../helpers/permissions';

// Custom components
import Pagination from '../../pagination/Pagination';
import Passport from './Passport';
import FormDialog from '../../dialogs/FormDialog';
import UniversalForm from '../../forms/UniversalForm';

// Icons
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExportIcon from '@material-ui/icons/ImportExport';

// Styles
import tableBlockStyles from '../../../assets/jss/components/filterableTable/table/tableBlockStyles';
const useStyles = makeStyles(tableBlockStyles);

const makeTableData = (tableData, fields, lang) => {
  return tableData.data.map((row) => {
    const result = {
      id: row.id,
    };

    fields.forEach((column) => {
      result[column.code] = row[column.code + lang]
        ? row[column.code + lang]
        : row[column.code]
        ? row[column.code]
        : '';
    });

    return result;
  });
};

const renderBodyCell = (bRow, hRow, lang) => {
  let content = bRow[hRow.code] !== null ? bRow[hRow.code] : '';

  if (content.hasOwnProperty('name' + lang)) {
    content = content['name' + lang];
  } 
  else if (hRow.code === 'document' || hRow.code === 'certificatesCertificateURL' || hRow.code === 'orderLink') {
    const linkFunc = (file, index = -1) => { 
      return (
        <div>
          <Link href={file} component="a" target="_blank"> 
            Документ { index !== -1 ? index + 1 : ""}
        </Link>
        </div>
      );
    };
    if(Array.isArray(content)){
      content = content.map(linkFunc);
    }
    else 
      return linkFunc(content);
    
  } 
  else if (Array.isArray(content)) {
    if (content.length > 0 && content[0].hasOwnProperty('name' + lang)) {
      var newContent = '';
      for (let i = 0; i < content.length; i++) {
        newContent += content[i]['name' + lang] + ', ';
      }

      content = newContent.substring(0, newContent.length - 2);
    } else content = content.join(', ');
  }

  return content;
};

const getFieldValue = (field, value) => {
  if (field.typeInWebCode === 'date' || field.typeInWebCode === 'datetime') {
    return !isEmpty(value) ? new Date(value) : null;
  }

  if (field.isMultiple) {
    return !isEmpty(value) ? value : [];
  } else {
    return !isEmpty(value) ? value : '';
  }
};

const TableBlock = ({
  lang,
  auth,
  handle,
  viewId,
  tableData,
  fields,
  hasPassport,
  filterValues,
  formFields,
  formFieldGroups,
  handlePageChange,
  handlePageLimitChange,
  onEditEnd,
  createNotifier,
  closeNotifier,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = makeTableData(tableData, fields, lang) || [];

  const [passportOpen, setPassportOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [validaionSchema, setValidaionSchema] = useState({});
  const [formContent, setFormContent] = useState('');

  useEffect(() => {
    if (formFields.length !== 0) {
      const schema = generateYupSchemaShape(formFields);

      setValidaionSchema(schema);
    }
  }, [formFields]);

  const hanldeRowDoubleClick = (id) => {
    if (!isEmpty(id)) {
      setSelectedId(id);
      setPassportOpen(true);
    }
  };

  const handleEditFormOpen = async (id) => {
    var result = await httpGet(
      `/api/${handle}/formData/${id}`,
      {},
      { createNotifier, closeNotifier, translator: t }
    );

    setFormContent(generateFormContent(result, id));
    setFormOpen(true);
    };

    const handleAddFileFormOpen = async (id) => {
      const fields = [
        {
          customHandle: null,
          dependOn: null,
          hidden: false,
          isMultiple: false,
          label: "Номер сертификата",
          mask: null,
          name: "CertificateNumber",
          participations: null,
          type: "text"
        },
        {
          customHandle: null,
          dependOn: null,
          hidden: false,
          isMultiple: false,
          label: "Срок выдачи",
          mask: null,
          name: "CertificateDate",
          participations: null,
          type: "text"
        },
        {
          customHandle: null,
          dependOn: null,
          hidden: false,
          isMultiple: false,
          label: "Сертификат",
          mask: null,
          name: "CertificateFile",
          participations: null,
          type: "file"
      }];
      setFormContent(
        <UniversalForm
          fields={fields}
          formFieldGroups={[]}
          validationSchema={validaionSchema}
          handleSubmit={(values) => handleAddFileFormSumbit(values, id)}
        />
      );
      setFormOpen(true);
    };

    const handleAddFileFormSumbit = async (values, id) => {
      try {
        let result;
        const formData = new FormData();
        formData.append('CertificatesCertificateNumber', values.CertificateNumber);
        formData.append('CertificatesCertificateDate', values.CertificateDate);
        formData.append('CertificateFile', values.CertificateFile);

        result = await httpPut(`/api/${handle}/AddFile/${id}`, formData, {
            createNotifier,
            closeNotifier,
            translator: t,
          }, { headers: { 'Content-Type': 'multipart/form-data' } });
          
        if (result) {
          onEditEnd();
          setFormOpen(false);
        }
      } catch (ex) {
        throw ex;
      }
    };

    const handleDelete = async (id) => {
        var result = await httpDelete(
            `/api/${handle}/Delete/${id}`,
            {},
            { createNotifier, closeNotifier, translator: t }
        );
        console.log(result);
        onEditEnd();
    };

 
  const handleCreateFormOpen = async (id) => {
    setFormContent(generateFormContent());
    setFormOpen(true);
  };

  const handleFormSumbit = async (values, id) => {
    try {
      let result;
      if (isEmpty(id)) {
        result = await httpPost(`/api/${handle}`, values, {
          createNotifier,
          closeNotifier,
          translator: t,
        });
      } else {
        result = await httpPut(`/api/${handle}/${id}`, values, {
          createNotifier,
          closeNotifier,
          translator: t,
        });
      }

      if (result) {
        onEditEnd();
        setFormOpen(false);
      }
    } catch (ex) {
      throw ex;
    }
  };

  const generateFormContent = (formFieldValues, id) => {
    if (isEmpty(formFieldValues)) {
      formFieldValues = [];
      formFields.forEach((f) => {
        if (f.code.includes('.')) {
          const pieces = f.code.split('.');
          const root = pieces[0];
          const code = pieces[1];

          formFieldValues[root] = [{}];
          formFieldValues[root][0][code] = null;
        } else {
          formFieldValues[f.code] = null;
        }
      });
    }

    const fields = formFields.reduce((acc, field) => {
      if (field.code.includes('.')) {
        const items = [];
        const pieces = field.code.split('.');
        const root = pieces[0];
        const code = pieces[1];

        if (isEmpty(formFieldValues[root])) return acc;
        const isEmptyArray = formFieldValues[root].length === 0;
        const count = !isEmptyArray ? formFieldValues[root].length : 1;
        for (let i = 0; i < count; i++) {
          items.push({
            name: `${root}.${i}.${code}`,
            type: field.typeInWebCode,
            isMultiple: field.isMultiple,
            hidden: field.isHidden,
            label: field.name['name' + lang],
            value: getFieldValue(
              field,
              !isEmptyArray ? formFieldValues[root][i][code] : null
            ),
            mask: field.mask,
            dependOn: field.dependOn,
            customHandle: field.customHandle,
            participations: field.participations,
          });
        }

        return [...acc, ...items];
      }

      return [
        ...acc,
        {
          name: field.code,
          type: field.typeInWebCode,
          isMultiple: field.isMultiple,
          hidden: field.isHidden,
          label: field.name['name' + lang],
          value: getFieldValue(field, formFieldValues[field.code]),
          mask: field.mask,
          dependOn: field.dependOn,
          customHandle: field.customHandle,
          participations: field.participations,
        },
      ];
    }, []);

    return (
      <UniversalForm
        fields={fields}
        formFieldGroups={formFieldGroups}
        validationSchema={validaionSchema}
        handleSubmit={(values) => handleFormSumbit(values, id)}
      />
    );
  };

    const editPermission = canEdit(auth.data, handle);
    const createPermission = canCreate(auth.data, handle);
    const deletePermission = canDelete(auth.data, handle);


  return (
    <Paper className={classes.root}>
      <div style={{ overflowX: 'auto' }}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {fields &&
              fields.map((hRow, idx) => (
                <TableCell key={idx} align={hRow.align}>
                  {hRow.name['name' + lang]}
                </TableCell>
              ))}
            {editPermission && (
              <TableCell align="center">{t('COMMON.ACTIONS')}</TableCell>
                          )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((bRow, index) => (
            <TableRow
              className={classes.row}
              key={index}
              onDoubleClick={() => hanldeRowDoubleClick(bRow['id'])}
            >
              {fields &&
                fields.map((hRow) => {
                  return (
                    <TableCell key={hRow.code} align={hRow.align}>
                      {renderBodyCell(bRow, hRow, lang)}
                      {editPermission && hRow.code === 'certificatesCertificateURL' ? (
                      <IconButton onClick={() => handleAddFileFormOpen(bRow['id'])}>
                          <AddIcon />
                      </IconButton>
                      ) : null}
                    </TableCell>
                  );
                })}
              {editPermission && (
                <TableCell align="center">
                  <IconButton onClick={() => handleEditFormOpen(bRow['id'])}>
                    <EditIcon />
                  </IconButton>
                  {deletePermission && (
                      <IconButton onClick={() => handleDelete(bRow['id'])}>
                          <DeleteIcon />
                      </IconButton>
                  )}
                </TableCell>
                  )}
                 
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        page={filterValues.page - 1}
        pageLimit={filterValues.pageLimit}
        count={tableData ? tableData.count : 0}
        handlePageChange={handlePageChange}
        handlePageLimitChange={handlePageLimitChange}
      />
      {createPermission && (
        <Fab className={classes.addButton} onClick={handleCreateFormOpen}>
          <AddIcon />
        </Fab>)
              }

      {hasPassport && selectedId !== null && (
        <Passport
          open={passportOpen}
          viewId={viewId}
          handle={handle}
          selectedId={selectedId}
          lang={lang}
          handleClose={setPassportOpen}
        />
      )}
      <FormDialog
        open={formOpen}
        title={t('COMMON.FILL_FORM')}
        content={formContent}
        handleClose={() => setFormOpen(false)}
        />
      </div>
    </Paper>
  );
};

export default TableBlock;
