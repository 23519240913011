import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import InputField from '../../../inputs/InputField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  title: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`,
  },
  inputsWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  item: {
    flex: 1,
    padding: `0 ${theme.spacing(2)}px`,
  },
  sendBtn: {
    margin: `${theme.spacing(2)}px auto`,
    display: 'block',
  },
}));

const defaultValues = {
  titleKk: '',
  titleRu: '',
  titleEn: '',
  bodyKk: '',
  bodyRu: '',
  bodyEn: '',
};

const PushForm = ({ count = null, handleFormSubmit, sending }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const {
    handleSubmit,
    setValue,
    errors,
    control,
    reset,
    watch,
    triggerValidation,
  } = useForm({
    defaultValues: defaultValues,
    validationSchema: yup.object().shape({
      titleKk: yup
        .string()
        .max(200, t('YUP.STRING.MAX').replace('{0}', 200))
        .required(t('YUP.STRING.REQUIRED')),
      titleRu: yup
        .string()
        .max(200, t('YUP.STRING.MAX').replace('{0}', 200))
        .required(t('YUP.STRING.REQUIRED')),
      titleEn: yup
        .string()
        .max(200, t('YUP.STRING.MAX').replace('{0}', 200))
        .required(t('YUP.STRING.REQUIRED')),
      bodyKk: yup
        .string()
        .max(1500, t('YUP.STRING.MAX').replace('{0}', 1500))
        .required(t('YUP.STRING.REQUIRED')),
      bodyRu: yup
        .string()
        .max(1500, t('YUP.STRING.MAX').replace('{0}', 1500))
        .required(t('YUP.STRING.REQUIRED')),
      bodyEn: yup
        .string()
        .max(1500, t('YUP.STRING.MAX').replace('{0}', 1500))
        .required(t('YUP.STRING.REQUIRED')),
    }),
  });

  useEffect(() => {
    i18n.on('languageChanged', async () => {
      for (let fieldName in errors) {
        await triggerValidation(fieldName);
      }
    });
    return () => {
      i18n.off('languageChanged', () => {});
    };
  }, [errors, i18n, triggerValidation]);

  const onSubmit = async (values) => {
    const success = await handleFormSubmit(values);
    if (success) reset(defaultValues);
  };

  const values = watch();

  return (
    <Paper className={classes.root}>
      {count !== null && (
        <Typography variant="h6" align="center" className={classes.title}>
          {t('PUSH_NOTIFICATION.FORM.TITLE').replace('{0}', count)}
        </Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Typography style={{ marginLeft: 40 }}>
          {t('PUSH_NOTIFICATION.FORM.SUB_TITLE')}
        </Typography>
        <div className={classes.inputsWrapper}>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="titleKk"
              label={t('COMMON.KK')}
              error={errors?.titleKk?.message}
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.titleKk}
            />
          </div>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="titleRu"
              label={t('COMMON.RU')}
              error={errors?.titleRu?.message}
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.titleRu}
            />
          </div>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="titleEn"
              label={t('COMMON.EN')}
              error={errors?.titleEn?.message}
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.titleEn}
            />
          </div>
        </div>
        <Typography style={{ marginLeft: 40 }}>
          {t('PUSH_NOTIFICATION.FORM.BODY')}
        </Typography>
        <div className={classes.inputsWrapper}>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="bodyKk"
              label={t('COMMON.KK')}
              error={errors?.bodyKk?.message}
              rows={12}
              multiline
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.bodyKk}
            />
          </div>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="bodyRu"
              label={t('COMMON.RU')}
              error={errors?.bodyRu?.message}
              rows={12}
              multiline
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.bodyRu}
            />
          </div>
          <div className={classes.item}>
            <Controller
              as={InputField}
              control={control}
              name="bodyEn"
              label={t('COMMON.EN')}
              error={errors?.bodyEn?.message}
              rows={12}
              multiline
              handleChange={(e) => setValue(e.target.name, e.target.value)}
              initialValue={values.bodyEn}
            />
          </div>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.sendBtn}
            disabled={sending}
          >
            {t('COMMON.SEND')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default PushForm;
