import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

const initialState = {};
const middlewares = [thunk];
const enhancers = [];

const isDevelopment = process.env.NODE_ENV === "development";

if (
  isDevelopment &&
  window !== undefined &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

export default createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middlewares), ...enhancers)
);
