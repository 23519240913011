import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import langReducer from './langReducer';
import snackbarReducer from './snackbarReducer';

export default combineReducers({
  auth: authReducer,
  snackbars: snackbarReducer,
  errors: errorReducer,
  locale: langReducer
});
