import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// Styles
import paginationActionsStyles from '../../assets/jss/components/pagination/paginationActionsStyles';
const useStyle = makeStyles(paginationActionsStyles);

const PaginationActions = props => {
  const classes = useStyle();
  const { page, rowsPerPage, count, onChangePage } = props;

  const handleClickFirstPage = event => {
    onChangePage(event, 0);
  };

  const handleClickPrevPage = event => {
    onChangePage(event, page - 1);
  };

  const handleClickNextPage = event => {
    onChangePage(event, page + 1);
  };

  const handleClickLastPage = event => {
    onChangePage(event, Math.floor(count / rowsPerPage));
  };

  return (
    <div className={classes.root}>
      <IconButton
        disabled={page === 0}
        aria-label="Первая страница"
        onClick={handleClickFirstPage}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        disabled={page === 0}
        aria-label="Предыдущая страница"
        onClick={handleClickPrevPage}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={page === Math.floor(count / rowsPerPage)}
        aria-label="Слудующая страница"
        onClick={handleClickNextPage}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={page === Math.floor(count / rowsPerPage)}
        aria-label="Последняя страница"
        onClick={handleClickLastPage}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default PaginationActions;
