export default theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '500px',
    zIndex: 100
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block'
  }
});
