export default theme => ({
  pagination: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row wrap',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        height: 150,
        justifyContent: 'space-between'
      }
    }
  }
});
