import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import ReferencesLogsFilters from './ReferencesLogsFilters';
import ReferencesLogsTable from './ReferencesLogsTable';
import { httpGet, httpPut } from './../../../helpers';
import Spinner from './../../common/Spinner';
import {
  createNotifier,
  closeNotifier,
} from '../../../actions/snackbarActions';
import { getInitialValuesOfFilters } from '../../../helpers/tableFilter';

const ReferencesLogs = ({ type, lang, createNotifier, closeNotifier }) => {
  const filterRef = useRef(null);
  const [filterSettings, setFilterSettings] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    pageLimit: 10,
  });
  const [data, setData] = useState(null);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  const getData = useCallback(
    async (filters) => {
      setDataLoading(true);
      var url =
        type === 'bypass'
          ? '/api/application/bypass'
          : '/api/references/referencesLogs';
      try {
        const references = await httpGet(
          url,
          { type, ...filters },
          { createNotifier, closeNotifier }
        );

        setData(references);
        setDataLoading(false);
      } catch (ex) {
        setDataLoading(false);
      }
    },
    [createNotifier, closeNotifier, type]
  );

  useEffect(() => {
    filterRef.current = true;

    const fetch = async () => {
      setFiltersLoading(true);
      var url =
        type === 'bypass'
          ? '/api/application/bypass/filters'
          : '/api/references/referencesLogsFilters';
      try {
        const filterSettings = await httpGet(
          url,
          { type },
          { createNotifier, closeNotifier }
        );

        if (filterRef.current) {
          setFilters({
            ...filters,
            ...getInitialValuesOfFilters(filterSettings),
          });

          setFilterSettings(filterSettings);
          setFiltersLoading(false);
        }
      } catch (ex) {
        if (filterRef.current) {
          setFiltersLoading(false);
        }
      }
    };

    if (Object.keys(filters).length === 2) fetch();

    return () => {
      filterRef.current = false;
    };
  }, [createNotifier, closeNotifier, type, filters]);

  useEffect(() => {
    if (filterSettings === null && Object.keys(filters).length > 2)
      getData(filters);
  }, [getData, filterSettings, filters]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    await getData(filters);
  };

  const clearFilters = useCallback(async () => {
    const initialFilterValues = {
      page: 1,
      pageLimit: 10,
      ...getInitialValuesOfFilters(filterSettings),
    };

    setFilters(initialFilterValues);
    await getData(initialFilterValues);
  }, [filterSettings, getData]);

  const handlePaginationAction = async (e) => {
    const { name, value } = e.target;

    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    await getData(newFilters);
  };

  return (
    <div>
      {filtersLoading ? (
        <Spinner />
      ) : (
        Object.keys(filters).length > 2 && (
          <ReferencesLogsFilters
            type={type}
            lang={lang}
            filters={filters}
            filterSettings={filterSettings}
            setFilters={setFilters}
            clearFilters={clearFilters}
            handleSumbit={handleFormSubmit}
          />
        )
      )}
      {dataLoading ? (
        <Spinner />
      ) : (
        data && (
          <ReferencesLogsTable
            type={type}
            data={data}
            filters={filters}
            handlePaginationAction={handlePaginationAction}
            lang={lang}
            httpGet={httpGet}
            httpPut={httpPut}
            createNotifier={createNotifier}
            closeNotifier={closeNotifier}
          />
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  ReferencesLogs
);
