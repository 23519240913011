import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';

import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import Pagination from '../../pagination/Pagination';
import FormDialog from '../../dialogs/FormDialog';
import TablePassport from './../../common/TablePassport';
import BypassAddStatusForm from './bypass/BypassAddStatusForm';

import tableBlockStyles from '../../../assets/jss/components/filterableTable/table/tableBlockStyles';
const useStyles = makeStyles(tableBlockStyles);

const ReferencesLogsTable = ({
  type,
  data: { fields, values, count },
  filters,
  lang,
  handlePaginationAction,
  httpGet,
  httpPut,
  createNotifier,
  closeNotifier,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [passportState, setPassportState] = useState({
    open: false,
    data: null,
    loading: false,
  });
  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    title: null,
    content: null,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [formDialogOpen, setDialogFormOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const onSelectAllClick = (event) => {
    setSelectedIds(
      event.target.checked
        ? values.map((bRow) => bRow['id']['name' + lang])
        : []
    );
  };

  const onSelectClick = (event, id) => {
    event.stopPropagation();

    setSelectedIds(
      event.target.checked
        ? [...selectedIds, id]
        : selectedIds.filter((x) => x !== id)
    );
  };

  const headers = [];
  for (let key in fields) {
    headers.push(
      <TableCell key={key} align="center">
        {key !== 'id' ? (
          fields[key]['name' + lang]
        ) : (
          <Checkbox
            indeterminate={
              selectedIds.length > 0 && selectedIds.length < values.length
            }
            checked={values.length > 0 && selectedIds.length === values.length}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        )}
      </TableCell>
    );
  }

  const renderBody = (hRow) => {
    const body = [];
    for (let key in fields) {
      let value = hRow[key] !== null ? hRow[key]['name' + lang] : '';
      let content = value;

      if (key === 'id' && type === 'bypass') {
        content = (
          <Checkbox
            checked={selectedIds.includes(value)}
            onClick={(e) => onSelectClick(e, value)}
            inputProps={{ 'aria-label': 'select one' }}
          />
        );
      } else if (key === 'document' && value !== null) {
        value = new URL(value);

        content = (
          <Link
            href={window.location.origin + value.pathname}
            component="a"
            target="_blank"
          >
            Document
          </Link>
        );
      } else if (type === 'applications' && key === 'files' && value !== null) {
        content = (
          <IconButton
            onClick={() => handleDialogOpen(hRow['id']['name' + lang], key)}
          >
            <DescriptionIcon />
          </IconButton>
        );
      } else if (
        (type === 'applications' || type === 'bypass') &&
        key === 'stages' &&
        value !== null
      ) {
        content = (
          <IconButton
            onClick={() => handleDialogOpen(hRow['id']['name' + lang], key)}
          >
            <FormatListNumberedIcon />
          </IconButton>
        );
      }

      body.push(
        <TableCell key={key} align="center">
          {content}
        </TableCell>
      );
    }

    return body;
  };

  const handleDialogOpen = async (id, columnType) => {
    setDialog({ ...dialog, loading: true });
    const application = await httpGet(
      `/api/application/stages/${id}`,
      { byUserGroup: type === 'bypass' },
      { createNotifier, closeNotifier, translator: t }
    );

    let newDialog = dialog;
    if (application !== null) {
      newDialog.open = true;

      if (columnType === 'files') {
        newDialog.title = t('REFERENCES_LOGS.FILES_TITLE');
        newDialog.content = (
          <List>
            {application.files.map((fileUrl, idx) => {
              const url = new URL(fileUrl);
              return (
                <ListItem key={idx}>
                  <Link
                    href={window.location.origin + url.pathname}
                    component="a"
                    target="_blank"
                  >
                    Document {idx + 1}
                  </Link>
                </ListItem>
              );
            })}
          </List>
        );
      } else if (columnType === 'stages') {
        newDialog.title = t('REFERENCES_LOGS.STAGES_TITLE');
        newDialog.content = (
          <Stepper orientation="vertical">
            {application.stages.map((stage, idx) => (
              <Step active={true} key={idx}>
                <StepLabel>{stage.status['name' + lang]}</StepLabel>
                <StepContent>
                  <Typography>
                    {stage.position['name' + lang]}:{' '}
                    {stage.employee['name' + lang]}
                  </Typography>
                  <Typography color="primary">
                    {stage.comment['name' + lang]}
                  </Typography>
                  <Typography variant="caption">
                    {new Date(stage.dateOfCreation).toLocaleDateString(
                      'ru-RU',
                      {
                        hour: 'numeric',
                        minute: 'numeric',
                      }
                    )}
                  </Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        );
      }
    }

    setDialog({ ...newDialog, loading: false });
  };

  const handlePassportOpen = async (event, id) => {
    event.stopPropagation();

    if (id && (type === 'applications' || type === 'bypass')) {
      setPassportState({
        ...passportState,
        loading: true,
        open: true,
      });
      var url =
        type === 'bypass'
          ? '/api/application/bypass/passport'
          : '/api/references/ReferencesLogsPassport';

      const passport = await httpGet(
        url,
        { type, elementId: id },
        { createNotifier, closeNotifier, translator: t }
      );

      setPassportState({
        ...passportState,
        data: passport,
        loading: false,
        open: true,
      });
    }
  };

  const handleFormSubmit = (values) => {
    setIsSending(true);
    var formData = { ...values, applicationIds: selectedIds };
    httpPut(`/api/application/bypass`, formData, {
      createNotifier,
      closeNotifier,
      translator: t,
    })
      .then((result) => {
        if (result.isSuccess) {
          setDialogFormOpen(false);
        }
      })
      .catch((ex) => {
        createNotifier({
          message: ex.status,
          options: {
            variant: 'warning',
            key: new Date().getTime() + Math.random(),
            action: (key) => (
              <IconButton onClick={() => closeNotifier(key)}>
                <CloseIcon />
              </IconButton>
            ),
          },
        });
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  return (
    <Paper className={classes.root}>
      <div style={{ overflowX: 'auto' }}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>{headers}</TableRow>
          </TableHead>
          <TableBody>
            {values.map((bRow, index) => (
              <TableRow
                className={classes.row}
                key={index}
                onDoubleClick={(e) =>
                  handlePassportOpen(e, bRow['id']['name' + lang])
                }
              >
                {renderBody(bRow)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Pagination
        page={filters.page - 1}
        pageLimit={filters.pageLimit}
        count={count}
        handlePageChange={handlePaginationAction}
        handlePageLimitChange={handlePaginationAction}
      />
      <TablePassport
        open={passportState.open}
        isLoading={passportState.loading}
        data={passportState.data}
        lang={lang}
        handleClose={() => setPassportState({ ...passportState, open: false })}
      />
      <FormDialog
        open={dialog.open}
        title={dialog.title}
        content={dialog.content}
        handleClose={() => setDialog({ ...dialog, open: false })}
      />
      <FormDialog
        open={formDialogOpen}
        title={'Добавление статуса'}
        content={
          <BypassAddStatusForm
            defaultValues={{
              status: '',
              comment: '',
            }}
            sending={isSending}
            handleFormSubmit={handleFormSubmit}
          />
        }
        handleClose={() => setDialogFormOpen(false)}
      />
      {fields.hasOwnProperty('id') && (
        <Fab
          onClick={(e) => {
            e.stopPropagation();
            setDialogFormOpen(true);
          }}
          className={classes.addButton}
          disabled={selectedIds.length === 0}
        >
          <EditIcon />
        </Fab>
      )}
    </Paper>
  );
};

export default ReferencesLogsTable;
