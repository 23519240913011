import React from 'react';
import MaskedInput from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// Styles
import inputFieldStyles from '../../assets/jss/components/inputs/inputStyles';
const useStyles = makeStyles(inputFieldStyles);

const InputField = ({
  name,
  label = '',
  initialValue,
  handleChange,
  type = 'text',
  error = null,
  mask = false,
  multiline = false,
  rows = 1,
}) => {
  const classes = useStyles();

  const content = mask ? (
    <MaskedInput
      mask={mask}
      value={initialValue}
      onChange={(e) => {
        e.target.value = e.target.value.replace(/\D+/g, '');
        handleChange(e);
      }}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          name={name}
          label={label}
          className={classes.input}
          type={type}
          variant="outlined"
          multiline={multiline}
          rows={rows}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </MaskedInput>
  ) : (
    <TextField
      name={name}
      label={label}
      value={initialValue}
      type={type}
      onChange={handleChange}
      className={classes.input}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      InputLabelProps={{ shrink: true }}
    />
  );

  return (
    <div className={classes.formGroup}>
      {content}
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default InputField;
