import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

// Custom components
import PaginationActions from './PaginationActions';

// Styles
import paginationStyles from '../../assets/jss/components/pagination/paginationStyles';
const useStyle = makeStyles(paginationStyles);

const Pagination = props => {
  const classes = useStyle();

  const {
    page,
    pageLimit,
    count,
    handlePageChange,
    handlePageLimitChange
  } = props;

  const { t } = useTranslation();

  return (
    <TablePagination
      className={classes.pagination}
      rowsPerPageOptions={[10, 20, 50]}
      component="div"
      page={page}
      rowsPerPage={pageLimit}
      count={count}
      labelRowsPerPage={t('PAGINATION.RECORDS_COUNT')}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}-${to} ${t('PAGINATION.FROM')} ${count}`;
      }}
      backIconButtonProps={{
        'aria-label': 'Previous Page'
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page'
      }}
      onChangePage={(e, value) =>
        handlePageChange({ target: { name: 'page', value: value + 1 } })
      }
      onChangeRowsPerPage={e =>
        handlePageLimitChange({
          target: { name: 'pageLimit', value: e.target.value }
        })
      }
      ActionsComponent={PaginationActions}
    />
  );
};

export default Pagination;
