import React, { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

// Custom components
import Spinner from './Spinner';

// Icons
import CloseIcon from '@material-ui/icons/Close';

// Styles
import passportStyles from '../../assets/jss/components/filterableTable/table/passportStyles';
import { isEmpty } from '../../helpers';
const useStyles = makeStyles(passportStyles);

const TablePassport = ({ data, isLoading, open, lang, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    !isEmpty(data) && (
      <Paper className={clsx(classes.root, { [classes.open]: open })}>
        <AppBar className={classes.appBar} position="relative">
          <ToolBar>
            <Typography variant="h4" className={classes.title}>
              {t('PASSPORT.TITLE')}
            </Typography>
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </ToolBar>
        </AppBar>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className={classes.body}>
            {data &&
              data.hasOwnProperty('properties') &&
              data.properties.map((p, idx) => (
                <Fragment key={idx}>
                  {data.countInGroup > 0
                    ? data.countInGroup &&
                      idx % data.countInGroup === 0 && <Divider />
                    : null}
                  <div key={idx} className={classes.property}>
                    <Typography variant="h6" className={classes.propertyTitle}>
                      {p.name['name' + lang]}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.propertyBody}
                    >
                      {p.value['name' + lang] &&
                      p.value['name' + lang].length > 0
                        ? p.value['name' + lang]
                        : '-'}
                    </Typography>
                  </div>
                </Fragment>
              ))}
          </div>
        )}
      </Paper>
    )
  );
};

export default TablePassport;
