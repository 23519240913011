import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

// Styles
import appBarMenuStyles from '../../assets/jss/components/menus/appBarMenuStyles';
const useStyle = makeStyles(appBarMenuStyles);

const AppBarMenu = ({ id, anchorEl, handleClose, items }) => {
  const classes = useStyle();

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      className={classes.menu}
    >
      {items.map(item => {
        if (item.to)
          return (
            <Link
              key={item.name}
              className={classes.link}
              component={RouterLink}
              to={item.to}
            >
              <MenuItem className={classes.menuItem} onClick={handleClose}>
                {item.name}
              </MenuItem>
            </Link>
          );

        return (
          <MenuItem
            key={item.name}
            className={classes.menuItem}
            onClick={item.handleClick ? item.handleClick : handleClose}
          >
            {item.name}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export default AppBarMenu;
