import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Icons
import AttachFile from '@material-ui/icons/AttachFile';

// Styles
import selectFieldStyles from '../../assets/jss/components/inputs/inputStyles';
const useStyles = makeStyles(selectFieldStyles);

const FileField = ({
  name,
  label = '',
  handleChange,
  error = null,
  accept = ''
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  const handleButtonClick = e => {
    inputRef.current.click();
  };

  return (
    <div className={classes.formGroup}>
      <Button variant="contained" onClick={handleButtonClick}>
        {label}
        <AttachFile />
        <input
          type="file"
          ref={inputRef}
          name={name}
          accept={accept}
          onChange={handleChange}
          className={classes.file}
        />
      </Button>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default FileField;
