import React, { useState, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// Custom components
import PrivateRoute from './common/PrivateRoute';
import NotFound from './common/NotFound';
import Notifier from './snackbar/Notifier';
import Header from './layouts/Header';
import SideBar from './navbars/SideBar';
import Dashboard from './dashboard/Dashboard';
import Login from './auth/Login';
import FilterTable from './filterableTable/FilterTable';
import Profile from './pages/Profile';
import AHDDataCollection from './pages/AHDDataCollection';
import Logs from './modules/references/Logs';
import Bypass from './modules/references/bypass/Bypass';
import PushNotification from './modules/push/PushNotification';

import Spinner from './common/Spinner';

// Styles
import appStyles from '../assets/jss/components/app';

// Lazy
const Schedule = React.lazy(() => import('./modules/schedule/Schedule'));
const useStyle = makeStyles(appStyles);

const App = (props) => {
  const classes = useStyle();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { auth } = props;

  const handleSideBarOpen = () => {
    setSideBarOpen(true);
  };

  const handleSideBarClose = () => {
    setSideBarOpen(false);
  };

  return (
    <div className={classes.root}>
      <Notifier />
      <Header sideBarOpen={sideBarOpen} handleSideBarOpen={handleSideBarOpen} />
      {auth.isAuthorized && (
        <SideBar
          open={sideBarOpen}
          handleSideBarClose={handleSideBarClose}
          auth={auth}
        />
      )}
      <main className={classes.content}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/tables/:handle" component={FilterTable} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/ahd" component={AHDDataCollection} />
          <PrivateRoute exact path="/references" component={Logs} />
          <PrivateRoute
            exact
            path="/push-notification"
            component={PushNotification}
          />
          <PrivateRoute exact path="/bypass" component={Bypass} />
          {auth.data.userGroup === 'admin' && (
            <Suspense fallback={<Spinner />}>
              <PrivateRoute exact path="/schedule" component={Schedule} />
            </Suspense>
          )}
          <Route component={NotFound} />
        </Switch>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(App);
