export default theme => ({
  container: {
    width: '100%',
    position: 'relative',
    flexGrow: 1,
    display: 'inline-flex',
    padding: 15,
    minWidth: 300
  },
  inputWrapper: {
    minWidth: 280,
    margin: '15px auto'
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputElement: {
    width: '100%',
    position: 'relative',
    flexGrow: 1
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    top: '100%',
    left: 0,
    right: 0
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  }
});
