import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'block',
    textAlign: 'center'
  },
  title: {
    fontSize: '24px',
    marginBottom: 20
  },
  link: {
    display: 'inline-block'
  }
}));

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.title} align="center">
        {t('NOT_FOUND.TITLE')}
      </Typography>
      <Link to="/" className={classes.link}>
        <Typography align="center" variant="button">
          {t('NOT_FOUND.GO_TO_MAIN')}
        </Typography>
      </Link>
    </div>
  );
};

export default NotFound;
