import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ToolBar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Custom components
import AppBarMenu from '../menus/AppBarMenu';
import LangMenu from '../menus/LangMenu';

// Styles
import topNavBarStyles from '../../assets/jss/components/navbars/topNavBarStyles';
const useStyle = makeStyles(topNavBarStyles);

const TopNavBar = (props) => {
  const classes = useStyle();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const { auth, drawerOpen, handleSideBarOpen } = props;

  const handleProfileMenuOpen = (e) => {
    setProfileAnchorEl(e.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogoutClick = () => {
    props.logoutUser();
    setProfileAnchorEl(null);
  };

  const { t } = useTranslation();

  return (
    <AppBar
      className={clsx(classes.appBar, { [classes.appBarShift]: drawerOpen })}
      position="fixed"
      color="inherit"
    >
      <ToolBar>
        {auth.isAuthorized && (
          <IconButton
            className={clsx(classes.menuHamburgerButton, {
              [classes.hide]: drawerOpen,
            })}
            color="inherit"
            aria-label="Menu"
            onClick={handleSideBarOpen}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h1" className={classes.title} color="inherit">
          SmartUniversity
          {/* <Link component={RouterLink} to="/" className={classes.titleLink}>
            SmartUniversity
          </Link> */}
        </Typography>
        <LangMenu />
        {auth.isAuthorized && (
          <div>
            <IconButton
              color="inherit"
              aria-label="Account of current user"
              aria-haspopup="true"
              aria-controls="profile-menu"
              onClick={handleProfileMenuOpen}
              className={classes.menuButton}
            >
              <AccountCircleIcon />
            </IconButton>
            <AppBarMenu
              id="profile-menu"
              anchorEl={profileAnchorEl}
              handleClose={handleProfileMenuClose}
              items={[
                {
                  name: t('APP_BAR_MENU.PROFILE'),
                  to: '/profile',
                },
                {
                  name: t('APP_BAR_MENU.EXIT'),
                  handleClick: handleLogoutClick,
                },
              ]}
            />
          </div>
        )}
      </ToolBar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
})(TopNavBar);
