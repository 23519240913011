import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const Profile = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6">Profile</Typography>
      </Grid>
    </Grid>
  );
};

export default Profile;
