import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Custom components
import TabPanel from './TabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const HorizontalTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const { data } = props;

  return (
    <Paper position="static" className={classes.root}>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="Horizontal tabs"
        className={classes.tabs}
        centered
      >
        {data.map(({ tab }, idx) => (
          <Tab
            key={idx}
            label={tab.label}
            id={`horizontal-tab-${idx}`}
            aria-controls={`horizontal-tabpanel-${idx}`}
          />
        ))}
      </Tabs>
      {data.map(({ tabpanel }, idx) => (
        <TabPanel key={idx} prefix="horizontal" value={value} index={idx}>
          {tabpanel}
        </TabPanel>
      ))}
    </Paper>
  );
};

export default HorizontalTabs;
