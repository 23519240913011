import React from 'react';
import { useTranslation } from 'react-i18next';
import HorizontalTabs from '../../tabs/HorizontalTabs';
import PushNotificationByFilter from './PushNotificationByFilter';
import PushNotificationByTopic from './PushNotificationByTopic';

const PushNotification = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      tab: {
        label: t('PUSH_NOTIFICATION.TABS.BY_FILTER'),
      },
      tabpanel: <PushNotificationByFilter />,
    },
    {
      tab: {
        label: t('PUSH_NOTIFICATION.TABS.BY_TOPIC'),
      },
      tabpanel: <PushNotificationByTopic />,
    },
  ];

  return <HorizontalTabs data={tabs} />;
};

export default PushNotification;
