import React from 'react';
import { connect } from 'react-redux';
import { createNotifier, closeNotifier } from '../../actions/snackbarActions';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

// Validation
import ahdDataCollectionSchema from '../../validation/ahd/ahdDataCollectionSchema';

import { httpPost } from '../../helpers';

// Custom components
import Title from '../title/Title';
import UniversalForm from '../forms/UniversalForm';

const AHDDataCollection = props => {
  const { t } = useTranslation();
  const { createNotifier, closeNotifier } = props;

  const fields = [
    {
      name: 'excelFile',
      type: 'file',
      hidden: false,
      label: t('AHD.LABELS.FILE'),
      value: '',
      accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    }
  ];

  const handleFormSubmit = async values => {
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }

    await httpPost(`/api/dataCollection/import`, formData, {
      createNotifier,
      closeNotifier,
      translator: t
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Title text={t('AHD.TITLE')} />
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <UniversalForm
            fields={fields}
            handleSubmit={handleFormSubmit}
            validationSchema={ahdDataCollectionSchema}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  null,
  { createNotifier, closeNotifier }
)(AHDDataCollection);
