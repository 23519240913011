export const canEdit = (user, handle) => {
  switch (user.userGroup) {
    case "admin":
      return true;
    case "human_recourses":
      return ["Student", "Employee", "Parent"].includes(handle);
    case "selection_committee":
      return ["Enrollment"].includes(handle);
    default:
      return false;
  }
}

export const canCreate = (user, handle) => {
  switch (user.userGroup) {
    case "admin":
      return true;
    case "human_recourses":
      return ["Student", "Employee", "Parent"].includes(handle);
    case "selection_committee":
      return ["Enrollment"].includes(handle);
    default:
      return false;
  }
}

export const canDelete = (user, handle) => {
    switch (user.userGroup) {
        default:
            return ["Enrollment"].includes(handle);
    }
}

export const canExport = (handle) => {
    return ["Enrollment", "Orders"].includes(handle);
}
