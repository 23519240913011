import {
  CREATE_NOTIFIER,
  CLOSE_NOTIFIER,
  REMOVE_NOTIFIER
} from '../actions/types';

const initialState = {
  notifications: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_NOTIFIER:
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };
    case CLOSE_NOTIFIER:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || action.payload.key === notification.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };
    case REMOVE_NOTIFIER:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.payload
        )
      };
    default:
      return state;
  }
}
