import { CREATE_NOTIFIER, CLOSE_NOTIFIER, REMOVE_NOTIFIER } from './types';

export const createNotifier = notification => dispatch => {
  const key = notification.options && notification.options.key;

  dispatch({
    type: CREATE_NOTIFIER,
    payload: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  });
};

export const closeNotifier = key => dispatch => {
  dispatch({
    type: CLOSE_NOTIFIER,
    payload: {
      key,
      dismissAll: !key
    }
  });
};

export const removeNotifier = key => dispatch => {
  dispatch({
    type: REMOVE_NOTIFIER,
    payload: key
  });
};
