import React from 'react';
import { isEmpty } from 'lodash';

// Custom components
import InputField from '../components/inputs/InputField';
import DateField from '../components/inputs/DateField';
import SelectField from '../components/inputs/SelectField';
import SelectFieldMultiple from '../components/inputs/SelectFieldMultiple';
import AutoCompleteSingle from '../components/inputs/AutoComplete/AutoCompleteSingle';
import AutoCompleteMultiple from '../components/inputs/AutoComplete/AutoCompleteMultiple';

export const getInitialValuesOfFilters = (filters) =>
  filters.reduce((acc, filter) => {
    if (filter.type === 'date' || filter.type === 'datetime') {
      acc[filter.code] =
        filter.initialValue !== null ? new Date(filter.initialValue) : null;
    } else {
      if (filter.multiple) {
        acc[filter.code] =
          filter.initialValue !== null ? filter.initialValue : [];
      } else {
        acc[filter.code] =
          filter.initialValue !== null ? filter.initialValue : '';
      }
    }

    return acc;
  }, {});

export const getInitialValueOfFilter = (filter) => {
  if (filter.type === 'date' || filter.type === 'datetime') {
    return filter.initialValue !== null ? new Date(filter.initialValue) : null;
  } else {
    if (filter.multiple) {
      return filter.initialValue !== null ? filter.initialValue : [];
    } else {
      return filter.initialValue !== null ? filter.initialValue : '';
    }
  }
};

export const createFilters = (
  lang,
  data = [],
  filterValues,
  handleChange,
  setErrors = () => {},
  errors = null
) => {
  if (data !== null && data !== false) {
    return data.map((filter) => {
      if (filter.enabled) {
        if (!isEmpty(filter.dependOn) && isEmpty(filterValues[filter.dependOn]))
          return null;

        switch (filter.type) {
          case 'string':
          case 'number':
          case 'email':
          case 'password':
            return (
              <InputField
                key={filter.code}
                type={filter.type}
                name={filter.code}
                maxValue={filter.maxValue}
                minValue={filter.minValue}
                initialValue={
                  filterValues[filter.code] !== null
                    ? filterValues[filter.code]
                    : ''
                }
                label={filter.name['name' + lang]}
                handleChange={handleChange}
                handleSetErrors={setErrors}
                error={errors && errors[filter.code]}
              />
            );
          case 'date':
          case 'datetime':
            return (
              <DateField
                key={filter.code}
                type={filter.type}
                name={filter.code}
                maxValue={filter.maxValue}
                minValue={filter.minValue}
                initialValue={filterValues[filter.code]}
                label={filter.name['name' + lang]}
                handleChange={handleChange}
                handleSetErrors={setErrors}
                error={errors && errors[filter.code]}
              />
            );
          case 'combobox':
            if (filter.multiple) {
              return (
                <SelectFieldMultiple
                  key={filter.code}
                  type={filter.type}
                  name={filter.code}
                  customHandle={filter.customHandler}
                  participations={filter.participations}
                  initialValue={filterValues[filter.code]}
                  label={filter.name['name' + lang]}
                  handleChange={handleChange}
                  dependency={
                    !isEmpty(filter.dependOn)
                      ? {
                          [filter.dependOn]: filterValues[filter.dependOn].join(
                            ','
                          ),
                        }
                      : null
                  }
                />
              );
            }

            return (
              <SelectField
                key={filter.code}
                type={filter.type}
                name={filter.code}
                customHandle={filter.customHandler}
                participations={filter.participations}
                initialValue={filterValues[filter.code]}
                label={filter.name['name' + lang]}
                handleChange={handleChange}
                dependency={
                  !isEmpty(filter.dependOn)
                    ? { [filter.dependOn]: filterValues[filter.dependOn] }
                    : null
                }
              />
            );
          case 'autocomplete':
            if (filter.multiple) {
              return (
                <AutoCompleteMultiple
                  key={filter.code}
                  name={filter.code}
                  label={filter.name['name' + lang]}
                  customHandle={filter.customHandler}
                  participations={filter.participations}
                  placeholder={filter.placeholder}
                  initialValue={
                    filterValues[filter.code] !== ''
                      ? filterValues[filter.code]
                      : []
                  }
                  handleChange={handleChange}
                  dependency={
                    !isEmpty(filter.dependOn)
                      ? {
                          [filter.dependOn]: filterValues[filter.dependOn].join(
                            ','
                          ),
                        }
                      : null
                  }
                />
              );
            }

            return (
              <AutoCompleteSingle
                key={filter.code}
                name={filter.code}
                label={filter.name['name' + lang]}
                customHandle={filter.customHandler}
                participations={filter.participations}
                placeholder={filter.placeholder}
                initialValue={filterValues[filter.code]}
                handleChange={handleChange}
                dependency={
                  !isEmpty(filter.dependOn)
                    ? { [filter.dependOn]: filterValues[filter.dependOn] }
                    : null
                }
              />
            );
          default:
            return null;
        }
      }
      return null;
    });
  }
};
