import React, { useState, useEffect } from 'react';
import Downshift from 'downshift';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import {
  createNotifier,
  closeNotifier,
} from '../../../actions/snackbarActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// Custom hooks
import { useHttpGet } from '../../../hooks/http';
import { isEmpty } from '../../../helpers';

// Styles
import autoCompleteStyles from '../../../assets/jss/components/inputs/autoCompleteStyles';
const useStyles = makeStyles(autoCompleteStyles);

const renderSuggesions = (value, arSuggesions = []) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : arSuggesions.filter((suggesion) => {
        const keep =
          count < 5 &&
          suggesion.label.trim().slice(0, inputLength).toLowerCase() ===
            inputValue;

        if (keep) count++;

        return keep;
      });
};

const AutoComplete = ({
  name,
  label,
  initialValue,
  placeholder,
  customHandle = null,
  dependency = null,
  participations = null,
  error = null,
  handleChange,
  createNotifier,
  closeNotifier,
  lang,
}) => {
  const classes = useStyles();

  const [inputVal, setInputVal] = useState('');
  const [selectedItemVal, setSelectedItemVal] = useState(initialValue);
  const [suggestions, setSuggestions] = useState([]);

  const [localDependency, setLocalDependency] = useState(dependency);
  useEffect(() => {
    if (!isEqual(dependency, localDependency)) {
      setLocalDependency(dependency);
      handleChange({ target: { name: name, value: '' } });
    }
  }, [dependency, localDependency, handleChange, name]);

  let params = localDependency !== null ? { ...localDependency } : {};
  if (!isEmpty(participations)) {
    params = { ...params, participations };
  }

  const [isLoading, fetchedData] = useHttpGet(
    `/api/directories/${customHandle !== null ? customHandle : name}`,
    [localDependency],
    params,
    {
      createNotifier,
      closeNotifier,
    }
  );

  useEffect(() => {
    fetchedData &&
      setSuggestions(
        fetchedData.map((item) => ({
          value: `${item.id}`,
          label: item.name['name' + lang],
        }))
      );
  }, [fetchedData, lang]);

  // To clear and update
  useEffect(() => {
    setSelectedItemVal(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isLoading) {
      const val = suggestions.filter(
        (suggestion) => suggestion.value === selectedItemVal
      );

      val[0] ? setInputVal(val[0].label) : setInputVal('');
    }
  }, [selectedItemVal, isLoading, suggestions]);

  // Actions
  const handleEscKeyDown = (event) => {
    if (
      selectedItemVal &&
      selectedItemVal.length &&
      inputVal.length &&
      event.key === 'Escape'
    ) {
      event.stopPropagation();
      setInputVal('');
      setSelectedItemVal('');
      handleChange({
        target: {
          name,
          value: '',
        },
      });
    }
  };

  const handleInputValueChange = (event) => {
    setInputVal(event.target.value);
  };

  const onDownshiftChange = (newValue) => {
    setInputVal(newValue !== null ? newValue.label : '');
    setSelectedItemVal(newValue !== null ? newValue.value : null);
    handleChange({
      target: {
        name,
        value: newValue !== null ? newValue.value : null,
      },
    });
  };

  return (
    <Downshift
      onChange={onDownshiftChange}
      inputValue={inputVal}
      selectedItem={selectedItemVal}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        inputValue,
        selectedItem,
      }) => {
        const { onBlur, onChange, onFocus, ref, ...inputProps } = getInputProps(
          {
            onKeyDown: handleEscKeyDown,
            placeholder,
          }
        );

        if (inputProps.value === null) {
          inputProps.value = '';
        }

        return (
          <div>
            <div className={classes.container}>
              <TextField
                name={name}
                className={classes.inputElement}
                label={label}
                InputLabelProps={getLabelProps({ shrink: true })}
                InputProps={{
                  inputRef: ref,
                  onBlur,
                  onFocus,
                  onChange: (e) => {
                    handleInputValueChange(e);
                    onChange(e);
                  },
                }}
                {...inputProps}
              />

              <div {...getMenuProps()} style={{ zIndex: 10 }}>
                {isOpen ? (
                  <Paper className={classes.paper}>
                    {renderSuggesions(inputValue, suggestions).map(
                      (suggesion, index) => (
                        <MenuItem
                          key={suggesion.value}
                          selected={highlightedIndex === index}
                          component="div"
                          style={{
                            fontWeight:
                              (inputValue || '').indexOf(suggesion.value) > -1
                                ? 500
                                : 400,
                          }}
                          {...getItemProps({
                            key: index,
                            index,
                            item: suggesion,
                          })}
                        >
                          {suggesion.label}
                        </MenuItem>
                      )
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
            {error && (
              <Typography
                component="div"
                variant="caption"
                color="error"
                style={{ textAlign: 'center' }}
              >
                {error}
              </Typography>
            )}
          </div>
        );
      }}
    </Downshift>
  );
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  AutoComplete
);
