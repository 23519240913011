export default theme => ({
  formGroup: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 280,
    margin: "15px auto"
  },
  input: {
    width: "100%",
    margin: "15px auto",
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius
  },
  formControl: {
    minWidth: 280,
    margin: "15px auto"
  },
  file: {
    display: "none"
  }
});
