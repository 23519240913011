import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './settings/store';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './settings/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import App from './components/App.jsx';
import './settings/i18n';
import jwt_decode from 'jwt-decode';
import { setAuthTokenToHeader } from './helpers';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { unregister } from './registerServiceWorker';

unregister();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Auth logic
if (localStorage.jwtToken) {
  // Set auth token to request header
  setAuthTokenToHeader(localStorage.jwtToken);
  // Decode token
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthorized
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    window.location.href = `/login?back=${window.location.href}`;
  }
}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <CssBaseline />
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  rootElement
);

// registerServiceWorker();
