import { SET_CURRENT_USER } from '../actions/types';
import { isEmpty } from '../helpers';

const initialState = {
  isAuthorized: false,
  data: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthorized: !isEmpty(action.payload),
        data: action.payload
      };
    default:
      return state;
  }
}
