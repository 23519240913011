// Errors
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// LANG
export const CHANGE_LANG = 'CHANGE_LANG';

// Users
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// SnackBars
export const CREATE_NOTIFIER = 'CREATE_NOTIFIER';
export const CLOSE_NOTIFIER = 'CLOSE_NOTIFIER';
export const REMOVE_NOTIFIER = 'REMOVE_NOTIFIER';

// Employers
export const GET_EMPLOYERS = 'GET_EMPLOYERS';
export const CREATE_EMPLOYER = 'CREATE_EMPLOYER';
export const EDIT_EMPLOYER = 'EDIT_EMPLOYER';
export const DELETE_EMPLOYER = 'DELETE_EMPLOYER';
// Employers filters
export const SET_EMPLOYERS_PAGE = 'SET_PAGE';
export const SET_EMPLOYERS_PAGE_LIMIT = 'SET_PAGE_LIMIT';
