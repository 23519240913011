import { SET_CURRENT_USER, GET_ERRORS, CLEAR_ERRORS } from './types';
import { createNotifier } from './snackbarActions';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { setAuthTokenToHeader } from '../helpers';

export const registerUser = (userData, history) => async (dispatch) => {
  try {
    await axios.post(`/api/authentication/register`, userData);

    history.push('/confirmCode');
  } catch (ex) {
    if (ex.response.data.errors) {
      dispatch({
        type: GET_ERRORS,
        payload: ex.response.data.errors,
      });
    } else {
      createNotifier({
        message: 'Произошла ошибка',
        options: {
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      });
    }
  }
};

export const confirmCode = (codeData, history, url = '/personalInfo') => async (
  dispatch
) => {
  try {
    await axios.post(`/api/authentication/confirmCode`, codeData);

    history.push(url);
  } catch (ex) {
    if (ex.response.data.errors) {
      dispatch({
        type: GET_ERRORS,
        payload: ex.response.data.errors,
      });
    } else {
      createNotifier({
        message: 'Произошла ошибка',
        options: {
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      });
    }
  }
};

export const setPersonalInfo = (userData, history) => async (dispatch) => {
  try {
    await axios.post(`/api/authentication/personalInfo`, userData);

    history.push('/setPassword');
  } catch (ex) {
    if (ex.response.data.errors) {
      dispatch({
        type: GET_ERRORS,
        payload: ex.response.data.errors,
      });
    } else {
      createNotifier({
        message: 'Произошла ошибка',
        options: {
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      });
    }
  }
};

export const setPassword = (userData, history) => async (dispatch) => {
  try {
    await axios.post(`/api/authentication/setPassword`, userData);

    history.push('/login');
  } catch (ex) {
    if (ex.response.data.errors) {
      dispatch({
        type: GET_ERRORS,
        payload: ex.response.data.errors,
      });
    } else {
      createNotifier({
        message: 'Произошла ошибка',
        options: {
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      });
    }
  }
};

export const loginUser = (userData) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    const result = await axios.post(`/api/authentication/login`, userData);

    const { token } = result.data;
    // Set token to localeStorage
    localStorage.setItem('jwtToken', token);
    // Set token to Auth header
    setAuthTokenToHeader(token);
    // Decode user data from token
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
  } catch (ex) {
    if (ex.response.data.errors) {
      dispatch({
        type: GET_ERRORS,
        payload: ex.response.data.errors,
      });
    } else {
      createNotifier({
        message: 'Произошла ошибка',
        options: {
          variant: 'error',
          key: new Date().getTime() + Math.random(),
        },
      });
    }
  }
};

export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});

export const logoutUser = () => (dispatch) => {
  // Remove token from localeStorage
  localStorage.removeItem('jwtToken');
  // Remove Auth token from header
  setAuthTokenToHeader(false);
  // Clear current user
  dispatch(setCurrentUser({}));
};

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});
