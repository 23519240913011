import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import {
  createNotifier,
  closeNotifier,
} from '../../../actions/snackbarActions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

// Helpers
import { httpGet, isEmpty } from '../../../helpers';

// Styles
import autoCompleteStyles from '../../../assets/jss/components/inputs/autoCompleteStyles';
const useStyles = makeStyles(autoCompleteStyles);

const AutoCompleteMultiple = ({
  name,
  label,
  initialValue = [],
  placeholder,
  error = null,
  handleChange,
  customHandle = null,
  dependency = null,
  participations = null,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const [localDependency, setLocalDependency] = useState(dependency);
  useEffect(() => {
    if (!isEqual(dependency, localDependency)) {
      setLocalDependency(dependency);
      handleChange({ target: { name: name, value: [] } });
    }
  }, [dependency, localDependency, handleChange, name]);

  useEffect(() => {
    let active = true;

    let params = localDependency !== null ? { ...localDependency } : {};
    if (!isEmpty(participations)) {
      params = { ...params, participations };
    }

    (async () => {
      const result = await httpGet(
        `/api/directories/${customHandle !== null ? customHandle : name}`,
        params,
        {
          createNotifier,
          closeNotifier,
        }
      );

      if (active) {
        setOptions(
          result.map((item) => ({
            value: item.id,
            label: item.name.nameRu,
          }))
        );
      }
    })();

    return () => {
      active = false;
    };
  }, [localDependency, customHandle, name, participations]);

  return (
    <div className={classes.inputWrapper}>
      <Autocomplete
        multiple
        id={`${name}`}
        open={open}
        onChange={(e, selected) =>
          handleChange({
            target: {
              name: name,
              value: selected.map((s) => s.value),
            },
          })
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={loading}
        value={options.filter((o) => initialValue.includes(o.value))}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.inputElement}
            label={label}
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {error && (
        <Typography
          component="div"
          variant="caption"
          color="error"
          style={{ textAlign: 'center' }}
        >
          {error}
        </Typography>
      )}
    </div>
  );
};

export default connect(null, {
  createNotifier,
  closeNotifier,
})(AutoCompleteMultiple);
