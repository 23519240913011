import 'date-fns';
import React, { Fragment } from 'react';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Styles
import inputFieldStyles from '../../assets/jss/components/inputs/inputStyles';
const useStyle = makeStyles(inputFieldStyles);

const DateField = ({
  name,
  label = '',
  initialValue = null,
  handleChange,
  type = 'date',
  error = null,
}) => {
  const classes = useStyle();

  const content =
    type === 'date' ? (
      <KeyboardDatePicker
        autoOk
        clearable
        helperText=""
        format="dd/MM/yyyy"
        name={name}
        label={label}
        inputVariant="outlined"
        value={initialValue}
        onChange={(value) =>
          handleChange({
            target: {
              name,
              value:
                value !== null
                  ? new Date(
                      value.getTime() - value.getTimezoneOffset() * 60000
                    )
                  : value,
            },
          })
        }
        className={classes.dateInput}
      />
    ) : (
      <KeyboardTimePicker
        autoOk
        clearable
        helperText=""
        name={name}
        label={label}
        inputVariant="outlined"
        value={initialValue}
        onChange={(value) =>
          handleChange({
            target: {
              name,
              value: new Date(
                value.getTime() - value.getTimezoneOffset() * 60000
              ),
            },
          })
        }
        className={classes.dateInput}
      />
    );

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        {content}
      </MuiPickersUtilsProvider>
      {error && (
        <Typography
          component="div"
          variant="caption"
          color="error"
          style={{ textAlign: 'center' }}
        >
          {error}
        </Typography>
      )}
    </Fragment>
  );
};

export default DateField;
