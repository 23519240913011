import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createNotifier, closeNotifier } from '../../actions/snackbarActions';
import qs from 'qs';
import { httpGet, httpPost, isEmpty } from '../../helpers';

// Custom components
import Spinner from '../common/Spinner';
import FilterBlock from './filter/FilterBlock';
import TableBlock from './table/TableBlock';

const fillHelper = (filter, forwardFilter, clearValues) => {
  let value;
  if (filter.type === 'date' || filter.type === 'datetime') {
    if (clearValues) value = null;
    else
      value =
        forwardFilter && !isEmpty(forwardFilter[filter.code])
          ? new Date(forwardFilter[filter.code])
          : filter.initialValue
          ? new Date(filter.initialValue)
          : null;
  } else {
    if (clearValues) {
      if (filter.multiple) value = [];
      else value = '';
    } else {
      if (filter.multiple) {
        value =
          forwardFilter && !isEmpty(forwardFilter[filter.code])
            ? filter.initialValue.split(',').map(v => v.trim())
            : filter.initialValue
            ? filter.initialValue.split(',').map(v => v.trim())
            : [];
      } else
        value =
          forwardFilter && !isEmpty(forwardFilter[filter.code])
            ? forwardFilter[filter.code]
            : filter.initialValue
            ? filter.initialValue
            : '';
    }
  }

  return value;
};

const defaultState = {
  handle: null,
  viewId: '',
  filterId: '',
  view: {
    registerSettings: [],
    fields: [],
    formFields: [],
    formFieldGroups: []
  },
  forwardFilterValues: {},
  filters: {
    defaults: {},
    others: {
      page: 1,
      pageLimit: 10
    }
  },
  tableData: {
    data: [],
    count: 0
  },
  isLoading: false
};

class FilterTable extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;

    this.getViewSetting = this.getViewSetting.bind(this);
    this.getForwardFilterValues = this.getForwardFilterValues.bind(this);
    this.fillFilters = this.fillFilters.bind(this);
    this.getTableData = this.getTableData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onFilterInputChange = this.onFilterInputChange.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onExportButtonClick = this.onExportButtonClick.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { handle } = this.props.match.params;
    const { viewId = null, filterId = null } = qs.parse(
      this.props.location.search.substring(1)
    );

    const { handle: oldHandle } = prevProps.match.params;
    const { viewId: oldViewId = null, filterId: oldFilterId = null } = qs.parse(
      prevProps.location.search.substring(1)
    );

    if (
      prevState.filters.others.page !== this.state.filters.others.page ||
      prevState.filters.others.pageLimit !== this.state.filters.others.pageLimit
    ) {
      await this.getTableData();
    }

    if (
      (oldHandle !== null && oldHandle !== handle) ||
      oldViewId !== viewId ||
      oldFilterId !== filterId
    ) {
      this.setState({
        ...defaultState,
        handle,
        viewId,
        filterId
      });
    }

    if (
      (prevState.handle !== null && prevState.handle !== this.state.handle) ||
      (prevState.viewId !== '' && prevState.viewId !== this.state.viewId) ||
      (prevState.filterId !== '' && prevState.filterId !== this.state.filterId)
    ) {
      await this.fetchData();
    }
  }

  async getViewSetting(handle, viewId = null) {
    const { createNotifier, closeNotifier } = this.props;
    const url = `/api/registers/getViewSettings`;
    const params = {
      collectionName: handle,
      viewId
    };

    return await httpGet(url, params, { createNotifier, closeNotifier });
  }

  async getForwardFilterValues(filterId) {
    const { createNotifier, closeNotifier } = this.props;
    const url = `/api/registers/getfilter`;
    const params = {
      id: filterId
    };

    return await httpGet(url, params, {
      createNotifier,
      closeNotifier
    });
  }

  fillFilters(clearValues = false) {
    const {
      view: { registerSettings: filters },
      forwardFilterValues
    } = this.state;

    const defaults = {};
    const others = {
      page: 1,
      pageLimit: 10
    };

    filters.forEach(f => {
      if (f.isDefault)
        defaults[f.code] = fillHelper(f, forwardFilterValues, false);
      else others[f.code] = fillHelper(f, forwardFilterValues, clearValues);
    });

    this.setState(
      {
        filters: {
          defaults: defaults,
          others
        }
      },
      async () => await this.getTableData()
    );
  }

  async getTableData() {
    this.setState({
      isLoading: true
    });

    const { createNotifier, closeNotifier } = this.props;
    const {
      handle,
      viewId,
      filters: { defaults, others }
    } = this.state;

    const filters = {
      ...defaults,
      ...others
    };

    const url = `/api/${handle}/get/${viewId}`;
    const tableData = await httpPost(url, filters, {
      createNotifier,
      closeNotifier
    });

    if (tableData) {
      this.setState({
        tableData,
        isLoading: false
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  async fetchData() {
    const { handle } = this.props.match.params;
    const { viewId = null, filterId = null } = qs.parse(
      this.props.location.search.substring(1)
    );
    this.setState({ handle, viewId, filterId });

    const view = await this.getViewSetting(handle, viewId);
    let forwardFilterValues = null;
    if (filterId !== null) {
      forwardFilterValues = await this.getForwardFilterValues(filterId);
    }

    if (!isEmpty(view)) {
      this.setState({
        view: {
          registerSettings: view.filters,
          fields: view.fields,
          formFields: view.formFields,
          formFieldGroups: view.formFieldGroups,
          hasPassport: view.hasPassport,
          export: true
        },
        forwardFilterValues
      });

      this.fillFilters();
    }
  }

  onFilterInputChange(e) {
    const { name, value } = e.target;

    this.setState(state => ({
      filters: {
        defaults: state.filters.defaults,
        others: {
          ...state.filters.others,
          [name]: value
        }
      }
    }));
  }

  async onSearchButtonClick(clear) {
    if (clear) this.fillFilters(true);
    else await this.getTableData();
  }

  async onExportButtonClick() { 
    const {
      handle,
      filters: { defaults, others }
    } = this.state;

    
    if(handle != "Orders") {
      window.open(`/api/${handle}/export`);
      return;
    }

    this.setState({
      isLoading: true
    });

    const { createNotifier, closeNotifier } = this.props;
    
    const filters = {
      ...defaults,
      ...others
    };

    const url = `/api/${handle}/Statement`;
    const exportUrl = await httpPost(url, filters, {
      createNotifier,
      closeNotifier
    });

    this.setState({
      isLoading: false
    });

    if (exportUrl) {
      window.open(exportUrl);
    } 
  }

  render() {
    const {
      viewId,
      filters,
      view: {
        registerSettings,
        fields,
        formFields,
        formFieldGroups,
        hasPassport
      },
      tableData,
      isLoading,
      handle
    } = this.state;

    return (
      <div className="FilterTable">
        {Object.keys(filters.others).length > 2 && (
          <FilterBlock
                    viewId={viewId}
                    lang={this.props.lang}
                    registersData={registerSettings.filter(s => s.isDefault !== true)}
                    handleChange={this.onFilterInputChange}
                    filterValues={filters.others}
                    handleSearchClick={this.onSearchButtonClick}
                    handleExportClick={this.onExportButtonClick}
                    handle={handle}
          />
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <TableBlock
            viewId={viewId}
            lang={this.props.lang}
            auth={this.props.auth}
            handle={handle}
            fields={fields}
            tableData={tableData}
            formFields={formFields}
            formFieldGroups={formFieldGroups}
            hasPassport={hasPassport}
            filterValues={filters.others}
            handlePageChange={this.onFilterInputChange}
            handlePageLimitChange={this.onFilterInputChange}
            onEditEnd={this.getTableData}
            createNotifier={this.props.createNotifier}
            closeNotifier={this.props.closeNotifier}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.locale.lang,
  auth: state.auth
});

export default connect(mapStateToProps, { createNotifier, closeNotifier })(
  FilterTable
);
