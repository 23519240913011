import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const TabPanel = ({ children, prefix, value, index, ...others }) => {
  return (
    <Typography
      component="div"
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`${prefix}-tabpanel-${index}`}
      aria-labelledby={`${prefix}-tab-${index}`}
      {...others}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
};

export default TabPanel;
