import React, { Component } from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// Custom Components
import InputField from '../inputs/InputField';
import SelectField from '../inputs/SelectField';

// Styles
const styles = theme => ({
  form: {
    width: 400,
    textAlign: 'center',
    padding: '30px 15px',
    margin: '15px auto',
    borderRadius: 5,
    boxShadow: '0 5px 15px rgba(0, 0, 0, .5)'
  },
  title: {
    marginBottom: 25
  },
  button: {
    margin: '15px 0 15px'
  },
  link: {
    marginTop: 10
  }
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Login: '',
      Password: '',
      RoleId: '',
      errors: {}
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    const { back = null } = qs.parse(this.props.location.search.substring(1));

    if (this.props.auth.isAuthorized)
      window.location.href = back != null ? back : '/';
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, t, auth, errors } = this.props;
    const { back = null } = qs.parse(this.props.location.search.substring(1));

    if (auth.isAuthorized)
      history.push(back != null ? new URL(back).pathname : '/');

    if (errors !== prevProps.errors) {
      let newErrors = errors;

      if (
        Array.isArray(this.props.errors) &&
        this.props.errors[0].field !== null
      ) {
        newErrors = errors.reduce(
          (accum, error) => ({
            ...accum,
            [error.field]: t(`LOGIN.${error.field.toUpperCase()}_${error.code}`)
          }),
          {}
        );
      }

      this.setState({ errors: newErrors });
    }
  }

  onInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const userData = {
      Login: this.state.Login,
      Password: this.state.Password,
      RoleId: this.state.RoleId
    };

    this.props.loginUser(userData);
  }

  render() {
    const { classes, t } = this.props;
    const { Login, Password, RoleId, errors } = this.state;

    return (
      <div className="Login">
        <form action="#" onSubmit={this.onFormSubmit} className={classes.form}>
          <Typography variant="h4" className={classes.title}>
            Smart University
          </Typography>
          <InputField
            name="Login"
            initialValue={Login}
            label={t('LOGIN.LOGIN')}
            handleChange={this.onInputChange}
            error={errors.Login}
          />
          <InputField
            name="Password"
            label={t('LOGIN.PASSWORD')}
            initialValue={Password}
            handleChange={this.onInputChange}
            type="password"
            error={errors.Password}
          />
          <SelectField
            name="RoleId"
            label={t('LOGIN.ROLE')}
            initialValue={RoleId}
            handleChange={this.onInputChange}
            error={errors.RoleId}
            customHandle="roles"
          />
          <div className={classes.button}>
            <Button variant="contained" color="primary" type="submit">
              {t('LOGIN.ENTER')}
            </Button>
          </div>
          <div>
            <Link to="/recover" component={RouterLink} className={classes.link}>
              {t('LOGIN.FORGOT_PASSWORD')}
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(
  withStyles(styles)(withTranslation()(Login))
);
