import { transition, primaryColors, whiteColor, link } from '../../base';

export default theme => ({
  menu: {
    padding: theme.spacing(2)
  },
  menuItem: {
    margin: 3,
    justifyContent: 'center',
    ...transition,
    '&:hover': {
      backgroundColor: primaryColors[0],
      color: whiteColor
    }
  },
  ...link
});
