import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { createFilters } from '../../../helpers/tableFilter';

import filterBlockStyles from './../../../assets/jss/components/filterableTable/filter/filterBlockStyles';
const useStyles = makeStyles(filterBlockStyles);

const ReferencesLogsFilters = ({
  type,
  lang,
  filters,
  filterSettings,
  handleSumbit,
  setFilters,
  clearFilters,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSumbit} className={classes.form}>
        {createFilters(lang, filterSettings, filters, handleChange)}

        <div className={classes.formActions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.btn}
          >
            {t('BUTTON.APPLY')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={async () => await clearFilters()}
          >
            {t('BUTTON.CLEAR')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default ReferencesLogsFilters;
