import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import XHR from 'i18next-xhr-backend';

// Resources
import commonKk from '../locales/kk/common.json';
import commonRu from '../locales/ru/common.json';
import commonEn from '../locales/en/common.json';

const resources = {
  kk: {
    common: commonKk
  },
  ru: {
    common: commonRu
  },
  en: {
    common: commonEn
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    ns: ['common'],
    defaultNS: 'common',
    fallbackNS: 'common',
    nonExplicitWhitelist: true,
    lng: 'ru', // 'kk' | 'en' | 'ru'
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  });

export default i18n;
