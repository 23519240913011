import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputField from '../../../inputs/InputField';
import SelectField from '../../../inputs/SelectField';

const useStyles = makeStyles((theme) => ({
  item: {
    flex: 1,
    padding: `0 ${theme.spacing(2)}px`,
  },
  sendBtn: {
    margin: `${theme.spacing(2)}px auto`,
    display: 'block',
  },
}));

const BypassAddStatusForm = ({ defaultValues, handleFormSubmit, sending }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const {
    handleSubmit,
    setValue,
    errors,
    control,
    reset,
    watch,
    triggerValidation,
  } = useForm({
    defaultValues: defaultValues,
    validationSchema: yup.object().shape({
      status: yup.string().required(t('YUP.STRING.REQUIRED')),
      comment: yup
        .string()
        .max(200, t('YUP.STRING.MAX').replace('{0}', 200))
        .when('status', {
          is: '5ed0cd1acd3ff31204d7b3dd',
          then: yup.string().required(t('YUP.STRING.REQUIRED')),
        }),
    }),
  });

  useEffect(() => {
    i18n.on('languageChanged', async () => {
      for (let fieldName in errors) {
        await triggerValidation(fieldName);
      }
    });
    return () => {
      i18n.off('languageChanged', () => {});
    };
  }, [errors, i18n, triggerValidation]);

  const onSubmit = async (values) => {
    const success = await handleFormSubmit(values);
    if (success) reset(defaultValues);
  };

  const values = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={classes.item}>
        <Controller
          as={SelectField}
          control={control}
          name="status"
          customHandle="applicationStatus"
          participations="bypass"
          label={t('COMMON.STATUS')}
          error={errors?.status?.message}
          handleChange={(e) => setValue(e.target.name, e.target.value)}
          initialValue={values.status}
        />
      </div>
      <div className={classes.item}>
        <Controller
          as={InputField}
          control={control}
          name="comment"
          label={t('COMMON.COMMENT')}
          rows={7}
          multiline
          error={errors?.comment?.message}
          handleChange={(e) => setValue(e.target.name, e.target.value)}
          initialValue={values.comment}
        />
      </div>
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.sendBtn}
          disabled={sending}
        >
          {t('COMMON.SEND')}
        </Button>
      </div>
    </form>
  );
};

export default BypassAddStatusForm;
