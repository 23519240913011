import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// Custom components
import { createFilters } from '../../../helpers/tableFilter';

import { canExport } from '../../../helpers/permissions';

// Styles
import filterBlockStyles from '../../../assets/jss/components/filterableTable/filter/filterBlockStyles';
const useStyle = makeStyles(filterBlockStyles);

const FilterBlock = props => {
  const classes = useStyle();
  const {
    viewId,
    lang,
    registersData,
    filterValues,
    handleChange,
    handleSearchClick,
    handleExportClick,
    handle
  } = props;

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const disabled = Object.keys(errors).filter(e => e === true);
  const handleSubmit = e => {
    e.preventDefault();

    handleSearchClick(false);
    };

  const exportPermission = canExport(handle) || viewId === "60e6b91b90ef8a227c94df27";
  
  return (
    <Paper className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        {createFilters(
          lang,
          registersData,
          filterValues,
          handleChange,
          setErrors,
          errors
        )}
        <div className={classes.formActions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.btn}
            disabled={disabled.length > 0}
          >
            {t('BUTTON.APPLY')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
                      onClick={() => handleSearchClick(true)}
          >
            {t('BUTTON.CLEAR')}
          </Button>

                  {exportPermission && (
                      <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          onClick={() => handleExportClick()}
                      >
                          {t('BUTTON.EXPORT')}
                      </Button>)
                  }
        </div>
      </form>
    </Paper>
  );
};

export default FilterBlock;
