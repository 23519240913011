import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

class Dashboard extends Component {
  render() {
    return (
      <div>
        <Typography variant="h1">Dashboard</Typography>
      </div>
    );
  }
}

export default Dashboard;
