import React from 'react';
import axios from 'axios';
import * as yup from 'yup';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export const setAuthTokenToHeader = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const isEmpty = (data) =>
  data === null ||
  data === undefined ||
  (typeof data === 'string' && data.trim().length === 0) ||
  (typeof data === 'object' && Object.keys(data).length === 0);

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getCurrentLang = () =>
  capitalizeFirstLetter(window.localStorage.i18nextLng);

export const httpGet = async (url, params = {}, config = {}) => {
  try {
    const result = await axios.get(url, { params });

    return result.data;
  } catch (ex) {
    const {
      createNotifier = function () {},
      closeNotifier = function () {},
      translator = function (path) {
        return 'Произошла ошибка.';
      },
    } = config;

    var errorText = translator('ERRORS.DEFAULT');
    if (!isEmpty(ex.response.data.Errors)) {
      errorText = translator(`ERRORS.${ex.response.data.Errors[0].ErrorCode}`);
    }

    createNotifier({
      message: errorText,
      options: {
        variant: 'warning',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return null;
  }
};

export const httpPost = async (url, body = {}, config = {}, headers = {}) => {
  const {
    createNotifier = function () {},
    closeNotifier = function () {},
    translator = function (path) {
      return 'Произошла ошибка.';
    },
  } = config;

  try {
    const result = await axios.post(url, body, headers);

    if (result.data.isSuccess)
      createNotifier({
        message: 'Успешная операция',
        options: {
          variant: 'success',
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <IconButton onClick={() => closeNotifier(key)}>
              <CloseIcon />
            </IconButton>
          ),
        },
      });

    return result.data;
  } catch (ex) {
    var errorText = translator('ERRORS.DEFAULT');
    if (!isEmpty(ex.response.data.message)) {
      errorText = ex.response.data.message;
    }
        
    createNotifier({
      message: errorText,
      options: {
        variant: 'warning',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return null;
  }
};

export const httpPut = async (url, body = {}, config = {}) => {
  const {
    createNotifier = function () {},
    closeNotifier = function () {},
    translator = function (path) {
      return 'Произошла ошибка.';
    },
    customMessageCode = null,
  } = config;

  try {
    const result = await axios.put(url, body);

    createNotifier({
      message: 'Успешная операция',
      options: {
        variant: 'success',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return result.data;
  } catch (ex) {
    var errorText =
      customMessageCode !== null
        ? translator(customMessageCode)
        : translator('ERRORS.DEFAULT');

    if (
      !isEmpty(ex.response.data.Errors) &&
      ex.response.data.Errors[0].Field !== null
    ) {
      const errors = castResponseToErrorsObject(
        translator,
        ex.response.data.Errors,
        'UNIVERSAL_FORM'
      );

      throw errors;
    }

    createNotifier({
      message: errorText,
      options: {
        variant: 'warning',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return null;
  }
};

export const httpDelete = async (url, config = {}) => {
  const {
    createNotifier = function () {},
    closeNotifier = function () {},
    translator = function (path) {
      return 'Произошла ошибка.';
    },
  } = config;

  try {
    const result = await axios.delete(url);

    createNotifier({
      message: 'Успешное удаление',
      options: {
        variant: 'success',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return result.data;
  } catch (ex) {
    var errorText = translator('ERRORS.DEFAULT');
    if (!isEmpty(ex.response.data.Errors)) {
      errorText = translator(`ERRORS.${ex.response.data.Errors[0].ErrorCode}`);
    }

    createNotifier({
      message: errorText,
      options: {
        variant: 'warning',
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <IconButton onClick={() => closeNotifier(key)}>
            <CloseIcon />
          </IconButton>
        ),
      },
    });

    return null;
  }
};

export const generateYupSchemaShape = (formFields) => {
  let comlpexProps = {};
  let fields = formFields.reduce((acc, field) => {
    if (field.code.includes('.')) {
      const pieces = field.code.split('.');
      const root = pieces[0];
      const code = pieces[1];

      if (root in comlpexProps) {
        comlpexProps[root] = {
          ...comlpexProps[root],
          [code]: yupHelper(field),
        };

        return acc;
      }

      comlpexProps[root] = {
        [code]: yupHelper(field),
      };

      return acc;
    }

    return { ...acc, [field.code]: yupHelper(field) };
  }, {});

  for (const key in comlpexProps) {
    fields[key] = yup.array().of(yup.object().shape(comlpexProps[key]));
  }

  return yup.object().shape(fields);
};

export const yupHelper = (field) => {
  let object;
  switch (field.typeInWebCode) {
    case 'number':
      object = yup
        .number()
        .nullable()
        .typeError('ERRORS.SCHEMA.INVALID_NUMBER');
      break;
    case 'date':
    case 'datetime':
      object = yup.date().nullable().typeError('ERRORS.SCHEMA.INVALID_DATE');
      break;
    default:
      if (field.isMultiple) {
        object = yup.array().typeError('ERRORS.SCHEMA.INVALID_ARRAY');
        break;
      }

      object = yup.string().typeError('ERRORS.SCHEMA.INVALID_STRING');
  }

  if (field.isHidden) return object;

  if (field.typeInWebCode === 'email')
    object = object.email('ERRORS.SCHEMA.INVALID_EMAIL');

  if (field.typeInWebCode === 'url')
    object = object.url('ERRORS.SCHEMA.INVALID_URL');

  if (field.minValue) {
    object = object.min(field.minValue, 'ERRORS.SCHEMA.INVALID_MIN_VALUE');
  } else if (field.minLength) {
    object = object.min(field.minLength, 'ERRORS.SCHEMA.INVALID_MIN_LENGTH');
  }

  if (field.maxValue) {
    object = object.max(field.maxValue, 'ERRORS.SCHEMA.INVALID_MAX_VALUE');
  } else if (field.maxLength) {
    object = object.max(field.maxLength, 'ERRORS.SCHEMA.INVALID_MAX_LENGTH');
  }

  if (field.isRequired) {
    object = object.required('ERRORS.SCHEMA.REQUIRED');
  }

  return object;
};

export const convertToWebFormat = (type) => {
  switch (type) {
    case 'textfield':
    case 'mobilenumber':
    case 'phonenumber':
      return 'text';
    default:
      return type;
  }
};

export const castResponseToErrorsObject = (t, response, formName) =>
  response.reduce(
    (obj, err) => ({
      ...obj,
      [err.Field]: t(
        `ERRORS.${formName}.${err.Field.toUpperCase()}.${err.ErrorCode}`
      ),
    }),
    {}
  );
