export default (theme) => ({
  root: {
    margin: '15px 0',
    minHeight: 500,
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  row: {
    cursor: 'pointer',
  },
  addButton: {
    position: 'fixed',
    right: 25,
    bottom: 25,
    zIndex: 10,
  }
});
